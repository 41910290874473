import React, { Component } from "react";
import {
  Card,
  Table,
  Form,
  Col,
  Row,
  Button,
  Tabs,
  Tab,
} from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import swal from "sweetalert";
import * as XLSX from "xlsx-js-style";
import {
  findStartDateAndEndDateOfMonth,
  DAYS_OF_WEEK,
} from "../../../../helper/times";
import api from "../../../../helper/axiosInstance";
import { IS_ALL_DAY, IS_STATUS, IS_VALID } from "../../../../constants";
import { toastError } from "../../../../helper/messages";
import DatePickerCustom from "../../../uiElements/DatePickerCustom";

class FinanceDayBook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schoolInfo: {},
      voucher: {},
      isLoading: false,
      listDay: [],
      daySelect: 0,
      start: new Date(),
      term: new Date(),
      weekInMonth: "",
      dayCheck: new Date(),
      // listGroup: [],
      groups: [],
      vouchers: [],
      timestampChose: 0,
      settings: [],
      setting: {},
      classesOfMonth: [],
      menu: [],
      tabkey: 1,

      totalStudentsDate: 0,
      totalMealsDate: 0,
      totalRevenueDate: 0,
      totalRefundDate: 0,
      totalFeeOtherDate: 0,
      totalIngredientExpensesDate: 0,
      totalMoneyDay: 0,
      totalMoneyMonth: 0,
      checkEmpty: "notEmpty",
    };
  }

  async componentDidMount() {
    let initialDate = new Date();
    let initialWeek = this.setDate(initialDate);

    let today = new Date();
    let todayIs = today.getDay();
    today.setHours(0, 0, 0, 0);
    const timestamp = today.getTime();

    const unixTimestamp = Math.floor(timestamp / 1000);

    today.setHours(23, 59, 59);
    const timestampEndOfDay = today.getTime();

    this.setState({ timestampChose: unixTimestamp });

    if (todayIs === 0) {
      todayIs = 7;
    }

    const newListDay = DAYS_OF_WEEK.map((day, index) => {
      return {
        id: day.id,
        day_name: day.day_name,
        day: initialWeek[index],
      };
    });

    this.setState({
      daySelect: todayIs,
      weekInMonth: (0 | (initialWeek[0].getDate() / 7)) + 1,
      listDay: newListDay,
    });

    this.getMenu(
      newListDay.find((day) => day.id === 1).day,
      newListDay.find((day) => day.id === 7).day
    );

    this.getDatas();

    this.getDetailschool();
  }

  setWeek = async (date = new Date()) => {
    const week = this.dates(date);
    this.setState({
      start: week[0],
      term: week[week.length - 1],
    });
  };

  getDatas = async (date = new Date()) => {
    date.setHours(0, 0, 0);
    const startOfDay = date.getTime();
    const timestampStartOfDay = Math.floor(startOfDay / 1000);

    date.setHours(23, 59, 59);
    const endOfDay = date.getTime();
    const timestampEndOfDay = Math.floor(endOfDay / 1000);

    const todayIs = date.getDay();

    const month = findStartDateAndEndDateOfMonth(date);
    const firstUnixTimestamp = month.startDate;
    this.setState({
      isLoading: true,
    });

    await this.getReportClass(
      firstUnixTimestamp,
      timestampEndOfDay,
      timestampStartOfDay
    );

    await this.getSettings(
      firstUnixTimestamp,
      timestampEndOfDay,
      timestampStartOfDay
    );

    let vouchers = await this.getVoucherMonth(
      firstUnixTimestamp,
      timestampEndOfDay,
      timestampStartOfDay
    );

    const classesOfMonth = this.state.classesOfMonth;
    const settings = this.state.settings;

    let totalStudentsDate = 0;
    let totalNotEatAndGoToSchoolDate = 0;
    let totalStudentOnValidLeaveDate = 0;
    let totalRevenueDate = 0;
    let totalFeeOtherDate = 0;
    let totalRefundDate = 0;
    let totalIngredientExpensesDate = 0;
    let totalMoneyDay = 0;
    let totalMoneyMonth = 0;

    vouchers?.map((voucher) => {
      const voucherDate = voucher.vou_date;

      const classOfMonth =
        classesOfMonth?.find(
          (classOfMonth) => classOfMonth.date === voucherDate
        ) || [];

      const classrooms = classOfMonth?.class;

      const settingOfday =
        settings?.find(
          (item) =>
            item.setting_date_start <= voucherDate &&
            item.setting_date_end >= voucherDate
        ) || {};

      const groups = settingOfday?.groups?.map((item) => ({
        ...item,
        countStudent: 0,
        countStudentOff: 0,
        countStudentOnValidLeave: 0,
      }));

      let totalStudents = 0;
      let totalNotEatAndGoToSchool = 0;
      let totalStudentOnValidLeave = 0;
      let totalRevenue = 0;
      let totalFeeOther = 0;
      let totalRefund = 0;
      let totalMoney = 0;
      if (classrooms && voucher) {
        classrooms?.forEach((classroom) => {
          const group = groups?.find(
            (group) => group.id === classroom.id_group
          );

          totalStudents += classroom.countStudent;
          totalNotEatAndGoToSchool += classroom.countNotEatAndGoToSchool;
          totalStudentOnValidLeave += classroom.countStudentOnValidLeave;

          const studentsNotEatAll =
            classroom?.studentsNotEat?.filter(
              (student) => student.is_all_day === IS_ALL_DAY.TRUE
            ) || [];

          const studentsOff =
            classroom?.studentsOff?.filter(
              (student) =>
                student.not_eat_all_day !== IS_ALL_DAY.TRUE &&
                student.is_valid > IS_VALID.FALSE
            ) || [];

          const studentsOffAndNotEatAll =
            classroom?.studentsOff?.filter(
              (student) =>
                student.not_eat_all_day === IS_ALL_DAY.TRUE &&
                student.is_valid >= IS_VALID.TRUE &&
                student.is_status === IS_STATUS.ACTIVE
            ) || [];

          const countStudentNotEat =
            studentsOffAndNotEatAll.length + studentsNotEatAll.length;

          const studentsNotEatTimes =
            classroom?.studentsNotEat?.filter(
              (student) => student.is_all_day === IS_ALL_DAY.FALSE
            ) || [];

          const discountStudentsNotEatTimes =
            studentsNotEatTimes?.reduce(
              (acc, current) => acc + parseInt(current.money_back),
              0
            ) +
            studentsOff?.reduce(
              (acc, current) => acc + parseInt(current.not_eat_money_back),
              0
            );

          const totalStudentMeals =
            classroom.countStudent -
            studentsNotEatAll.length -
            classroom.countStudentOnValidLeave;

          totalRevenue +=
            parseInt(group?.price_meal) *
              (classroom.countStudent - countStudentNotEat) -
            discountStudentsNotEatTimes;

          totalFeeOther += parseInt(group?.fee_other) * totalStudentMeals;

          studentsOff?.map((student) => {
            if (student.not_eat_id_times !== null) {
              totalRefund +=
                parseInt(group?.price_return) - student.not_eat_money_back;
            } else {
              totalRefund += parseInt(group?.price_return);
            }
            return student;
          });

          return classroom;
        });

        totalMoney =
          totalRevenue - voucher.vou_price - totalRefund - totalFeeOther;

        if (classOfMonth.date === timestampStartOfDay) {
          totalStudentsDate = totalStudents;
          totalNotEatAndGoToSchoolDate = totalNotEatAndGoToSchool;
          totalStudentOnValidLeaveDate = totalStudentOnValidLeave;
          totalRevenueDate = totalRevenue;
          totalFeeOtherDate = totalFeeOther;
          totalRefundDate = totalRefund;
          totalIngredientExpensesDate = voucher.vou_price;
          totalMoneyDay = totalMoney;
        }

        totalMoneyMonth += totalMoney;
      }

      return voucher;
    });

    const totalMealsDate =
      totalStudentsDate -
      totalNotEatAndGoToSchoolDate -
      totalStudentOnValidLeaveDate;

    this.setState({
      timestampChose: timestampStartOfDay,
      daySelect: todayIs,
      totalStudentsDate: totalStudentsDate,
      totalMealsDate: totalMealsDate,
      totalRevenueDate: totalRevenueDate,
      totalFeeOtherDate: totalFeeOtherDate,
      totalRefundDate: totalRefundDate,
      totalIngredientExpensesDate: totalIngredientExpensesDate,
      totalMoneyDay: totalMoneyDay,
      totalMoneyMonth: totalMoneyMonth,
      isLoading: false,
    });
  };

  getMenu = (startDate, endDate) => {
    let start = startDate?.setHours(0, 0, 0) / 1000;
    let end = endDate?.setHours(0, 0, 0) / 1000;
    api
      .get(`menu-show`, {
        params: {
          start_at: start,
          term_at: end,
        },
      })
      .then((res) => {
        if (res.data.errCode === 0) {
          this.setState({ menu: res.data });
        } else {
        }
      })
      .catch((error) => {});
  };

  //#region Fetch Data
  getDetailschool = () => {
    api.get(`/detailSchool`).then((res) => {
      if (res.data.status === true) {
        this.setState({
          schoolInfo: res.data.data[0],
        });
      }
    });
  };

  getListVoucher = (startDate, endDate) => {
    let start = startDate.setHours(0, 0, 0) / 1000;
    let end = endDate.setHours(23, 59, 59) / 1000;
    api
      .get(`/voucher-show?start_at=${start}&term_at=${end}`)
      .then((res) => {
        if (res.data.errCode === 0) {
          this.setState({
            voucher: res.data.vouchers,
          });
        }
      })
      .catch((e) => {});
  };

  getSettings = async (start_date, end_date, selected_date) => {
    await api
      .get(
        `/get-setting-by-date-period?start_date=${start_date}&end_date=${end_date}`
      )
      .then(async (res) => {
        const settings = res.data.data;

        if (settings.length === 0) {
          this.setState({ checkEmpty: "settingEmpty" });
        }

        const settingOfday =
          settings?.find(
            (item) =>
              item.setting_date_start <= selected_date &&
              item.setting_date_end >= selected_date
          ) || null;

        const groups = settingOfday.groups.map((item) => ({
          ...item,
          countStudent: 0,
          countStudentOff: 0,
          countStudentOnValidLeave: 0,
        }));

        this.setState({ settings: settings, groups: groups });
      })
      .catch((error) => {});
  };

  //tìm sĩ số theo nhóm, nhân tiền
  getReportClass = async (start_date, end_date) => {
    await api
      .get(`/report-class?start_date=${start_date}&end_date=${end_date}`)
      .then((res) => {
        const classesOfMonth = res.data.data || [];
        this.setState({ classesOfMonth: classesOfMonth });
      })
      .catch((error) => {
        toastError(error);
      });
  };

  getVoucherMonth = async (firstDay, lastDay, dateSelected) => {
    return await api
      .get(`/voucher-show?start_at=${firstDay}&term_at=${lastDay}`)
      .then(async (res) => {
        let vouchers = res.data?.vouchers || [];

        const voucher = vouchers.find(
          (voucher) => voucher.vou_date === dateSelected
        );

        vouchers = vouchers.sort((a, b) => a.vou_date - b.vou_date);

        this.setState({
          vouchers: vouchers,
          voucher: voucher,
        });

        return vouchers;
      })
      .catch((error) => {
        return [];
      });
  };
  //end get v2

  //#region Handle Logic
  xport = () => {
    const wb = XLSX.utils.book_new();

    const ws = this.book();

    XLSX.utils.book_append_sheet(wb, ws, "Bảng công khai chi chợ ngày");

    XLSX.writeFile(wb, "Bảng công khai chi chợ ngày.xlsx");
  };

  book = () => {
    const table = document.getElementById("finance-day-book");

    var ws = XLSX.utils.table_to_sheet(table, { raw: true });

    const colAlpha = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
    ];

    const borderStyle = {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
      right: { style: "thin" },
    };

    // Style cell
    colAlpha.map((alpha) => {
      ws[`${alpha}1`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "start",
        },
      };

      return alpha;
    });

    colAlpha.map((alpha) => {
      ws[`${alpha}2`].s = {
        font: {
          name: "Times New Roman",
          sz: 16,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
      };

      return alpha;
    });

    colAlpha.map((alpha) => {
      ws[`${alpha}3`].s = {
        font: {
          name: "Times New Roman",
        },
        alignment: {
          vertical: alpha == "J" ? "top" : "center",
          horizontal: alpha == "J" ? "start" : "center",
          wrapText: true,
        },
      };

      return alpha;
    });

    colAlpha.map((alpha) => {
      ws[`${alpha}4`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
        },
        border: borderStyle,
        alignment: {
          vertical: "center",
          horizontal: "center",
          wrapText: true,
        },
      };

      return alpha;
    });

    const storeItems =
      this.state.voucher?.ingredents?.filter(
        (item) => item.ingredent_type === 2
      ) || [];
    const marketItems =
      this.state.voucher?.ingredents?.filter(
        (item) => item.ingredent_type === 1
      ) || [];

    let bigArray = [];

    const rows = [
      {},
      { hpt: 30 },
      { hpt: this.state?.groups?.length * 17 + 17 },
    ];

    if (storeItems.length > marketItems.length) {
      bigArray = storeItems;
    } else {
      bigArray = marketItems;
    }

    for (let i = 0; i < bigArray.length + 6; i++) {
      colAlpha.map((alpha) => {
        if (ws[`${alpha}${5 + i}`].v === "empty") {
          ws[`${alpha}${5 + i}`].v = " ";
        }

        if (alpha === "A" || alpha === "G") {
          ws[`${alpha}${5 + i}`].s = {
            font: {
              name: "Times New Roman",
            },
            border: borderStyle,
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
          };
        }

        if (alpha === "B" || alpha === "H" || alpha === "C" || alpha === "I") {
          ws[`${alpha}${5 + i}`].s = {
            font: {
              name: "Times New Roman",
            },
            border: borderStyle,
            alignment: {
              vertical: "center",
              horizontal: "left",
            },
          };
        }

        if (
          alpha === "D" ||
          alpha === "E" ||
          alpha === "F" ||
          alpha === "J" ||
          alpha === "K" ||
          alpha === "L"
        ) {
          ws[`${alpha}${5 + i}`].s = {
            font: {
              name: "Times New Roman",
            },
            border: borderStyle,
            alignment: {
              vertical: "center",
              horizontal: "right",
            },
          };
        }

        return alpha;
      });

      rows.push({});
    }

    // Near last row
    colAlpha.map((alpha) => {
      if (alpha === "A" || alpha === "G") {
        ws[`${alpha}${5 + bigArray.length}`].s = {
          font: {
            name: "Times New Roman",
            bold: true,
          },
          border: borderStyle,
          alignment: {
            vertical: "center",
            horizontal: "center",
          },
        };
      } else if (alpha === "F" || alpha === "L") {
        ws[`${alpha}${5 + bigArray.length}`].s = {
          font: {
            name: "Times New Roman",
            bold: true,
          },
          border: borderStyle,
          alignment: {
            vertical: "center",
            horizontal: "right",
          },
        };
      } else {
        ws[`${alpha}${5 + bigArray.length}`].s = {
          font: {
            name: "Times New Roman",
          },
          border: borderStyle,
          alignment: {
            vertical: "center",
            horizontal: "center",
          },
        };
      }

      return alpha;
    });

    rows.push({});

    // Last row
    for (let i = 0; i <= 4; i++) {
      colAlpha.map((alpha) => {
        if (alpha === "A" || alpha === "D" || alpha === "G" || alpha === "J") {
          ws[`${alpha}${5 + bigArray.length + 1 + i}`].s = {
            font: {
              name: "Times New Roman",
              bold: true,
            },
            border: borderStyle,
            alignment: {
              wrapText: true,
              vertical: "center",
              // horizontal: "center",
            },
          };
        } else if (
          alpha === "C" ||
          alpha === "F" ||
          alpha === "I" ||
          alpha === "L"
        ) {
          ws[`${alpha}${5 + bigArray.length + 1 + i}`].s = {
            font: {
              name: "Times New Roman",
              bold: true,
            },
            border: borderStyle,
            alignment: {
              wrapText: true,
              vertical: "center",
              horizontal: "right",
            },
          };
        } else {
          ws[`${alpha}${5 + bigArray.length + 1 + i}`].s = {
            font: {
              name: "Times New Roman",
            },
            border: borderStyle,
            alignment: {
              wrapText: true,
              vertical: "center",
              horizontal: "center",
            },
          };
        }

        return alpha;
      });
    }

    rows.push({ hpt: 30 });

    ws["!cols"] = [
      { wch: 4 },
      { wch: 15 },
      { wch: 10 },
      { wch: 8 },
      { wch: 8.5 },
      { wch: 10.5 },
      { wch: 4 },
      { wch: 15 },
      { wch: 10 },
      { wch: 8 },
      { wch: 8.5 },
      { wch: 10.5 },
    ];

    ws["!rows"] = rows;

    const merge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 11 } },
      { s: { r: 1, c: 0 }, e: { r: 1, c: 11 } },

      { s: { r: 2, c: 0 }, e: { r: 2, c: 2 } },
      { s: { r: 2, c: 3 }, e: { r: 2, c: 5 } },
      { s: { r: 2, c: 6 }, e: { r: 2, c: 8 } },
      { s: { r: 2, c: 9 }, e: { r: 2, c: 11 } },

      {
        s: { r: 5 + bigArray.length - 1, c: 0 },
        e: { r: 5 + bigArray.length - 1, c: 4 },
      },
      {
        s: { r: 5 + bigArray.length - 1, c: 6 },
        e: { r: 5 + bigArray.length - 1, c: 10 },
      },
    ];
    for (let i = 0; i <= 4; i++) {
      merge.push({
        s: { r: 5 + bigArray.length + i, c: 0 },
        e: { r: 5 + bigArray.length + i, c: 5 },
      });
      merge.push({
        s: { r: 5 + bigArray.length + i, c: 6 },
        e: { r: 5 + bigArray.length + i, c: 10 },
      });
    }
    ws["!merges"] = merge;

    return ws;
  };
  xport2 = () => {
    const wb = XLSX.utils.book_new();

    const ws = this.book2();

    XLSX.utils.book_append_sheet(wb, ws, "Bảng công khai chi chợ ngày");

    XLSX.writeFile(wb, "Bảng công khai chi chợ ngày.xlsx");
  };

  book2 = () => {
    const table = document.getElementById("finance-day-book2");

    var ws = XLSX.utils.table_to_sheet(table, { raw: true });

    const colAlpha = ["A", "B", "C", "D", "E", "F", "G"];

    const borderStyle = {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
      right: { style: "thin" },
    };

    // Style cell
    ws[`A1`].s = {
      font: {
        name: "Times New Roman",
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "start",
      },
    };
    ws[`A2`].s = {
      font: {
        sz: 16,
        name: "Times New Roman",
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };
    ws[`A3`].s = {
      font: {
        name: "Times New Roman",
      },
      alignment: {
        vertical: "center",
        horizontal: "start",
      },
    };
    ws[`E3`].s = {
      font: {
        name: "Times New Roman",
      },
      alignment: {
        vertical: "center",
        horizontal: "start",
        wrapText: true,
      },
    };

    colAlpha.map((alpha) => {
      ws[`${alpha}4`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
        },
        border: borderStyle,
        alignment: {
          vertical: "center",
          horizontal: "center",
          wrapText: true,
        },
      };

      return alpha;
    });

    const storeItems =
      this.state.voucher?.ingredents?.filter(
        (item) => item.ingredent_type === 2
      ) || [];

    const marketItems =
      this.state.voucher?.ingredents?.filter(
        (item) => item.ingredent_type === 1
      ) || [];

    const rows = [
      {},
      { hpt: 30 },
      { hpt: this.state?.groups?.length * 17 + 17 },
    ];

    for (
      let i = 0;
      i <= marketItems?.length + storeItems?.length + 3 + 7;
      i++
    ) {
      colAlpha.map((alpha) => {
        if (ws[`${alpha}${5 + i}`].v === "empty") {
          ws[`${alpha}${5 + i}`].v = " ";
        }

        if (alpha === "A") {
          ws[`${alpha}${5 + i}`].s = {
            font: {
              name: "Times New Roman",
            },
            border: borderStyle,
            alignment: {
              vertical: "top",
              horizontal: "left",
              wrapText: true,
            },
          };
        }

        if (alpha === "B" || alpha === "H" || alpha === "C" || alpha === "I") {
          ws[`${alpha}${5 + i}`].s = {
            font: {
              name: "Times New Roman",
            },
            border: borderStyle,
            alignment: {
              vertical: "center",
              horizontal:
                alpha === "B" &&
                (5 + i === marketItems?.length + storeItems?.length + 8 ||
                  5 + i === marketItems?.length + 6)
                  ? "right"
                  : alpha === "B" &&
                    (i === 0 || i - marketItems?.length - 3 === -1)
                  ? "left"
                  : alpha === "B"
                  ? "center"
                  : "left",
              wrapText: true,
            },
          };
        }

        if (alpha === "D" || alpha === "E" || alpha === "F" || alpha === "G") {
          ws[`${alpha}${5 + i}`].s = {
            font: {
              name: "Times New Roman",
            },
            border: borderStyle,
            alignment: {
              vertical: "center",
              wrapText: true,
              horizontal: "right",
            },
          };
        }

        return alpha;
      });
    }

    ws["!cols"] = [
      { wch: 25 },
      { wch: 5 },
      { wch: 20 },
      { wch: 8 },
      { wch: 8 },
      { wch: 8 },
      { wch: 10 },
    ];

    ws["!rows"] = rows;

    const merge = [
      {
        s: { r: 4, c: 0 },
        e: { r: marketItems?.length + 5 + storeItems?.length + 2, c: 0 },
      },

      { s: { r: 0, c: 0 }, e: { r: 0, c: 3 } },
      { s: { r: 1, c: 0 }, e: { r: 1, c: 6 } },
      // { s: { r: 2, c: 0 }, e: { r: 2, c: 1 } },
      { s: { r: 2, c: 4 }, e: { r: 2, c: 6 } },
      { s: { r: 4, c: 1 }, e: { r: 4, c: 6 } },
      {
        s: { r: marketItems?.length + 5, c: 1 },
        e: { r: marketItems?.length + 5, c: 5 },
      },

      // hàng kho
      {
        s: { r: marketItems?.length + 6, c: 1 },
        e: { r: marketItems?.length + 6, c: 6 },
      },
      {
        s: { r: marketItems?.length + 5 + storeItems?.length + 2, c: 1 },
        e: { r: marketItems?.length + 5 + storeItems?.length + 2, c: 5 },
      },
      {
        s: { r: marketItems?.length + 5 + storeItems?.length + 2 + 1, c: 0 },
        e: { r: marketItems?.length + 5 + storeItems?.length + 2 + 1, c: 5 },
      },
      {
        s: { r: marketItems?.length + 5 + storeItems?.length + 2 + 2, c: 0 },
        e: { r: marketItems?.length + 5 + storeItems?.length + 2 + 2, c: 5 },
      },
      {
        s: { r: marketItems?.length + 5 + storeItems?.length + 2 + 3, c: 0 },
        e: { r: marketItems?.length + 5 + storeItems?.length + 2 + 3, c: 5 },
      },
      {
        s: { r: marketItems?.length + 5 + storeItems?.length + 2 + 4, c: 0 },
        e: { r: marketItems?.length + 5 + storeItems?.length + 2 + 4, c: 5 },
      },
      {
        s: { r: marketItems?.length + 5 + storeItems?.length + 2 + 5, c: 0 },
        e: { r: marketItems?.length + 5 + storeItems?.length + 2 + 5, c: 5 },
      },
      {
        s: { r: marketItems?.length + 5 + storeItems?.length + 2 + 6, c: 0 },
        e: { r: marketItems?.length + 5 + storeItems?.length + 2 + 6, c: 5 },
      },
      {
        s: { r: marketItems?.length + 5 + storeItems?.length + 2 + 7, c: 0 },
        e: { r: marketItems?.length + 5 + storeItems?.length + 2 + 7, c: 5 },
      },
    ];
    ws["!merges"] = merge;

    return ws;
  };

  handleExport = () => {
    swal({
      title: `Xác nhận`,
      text: `Bạn muốn xuất file sổ tài chính công khai`,
      icon: "info",
      buttons: ["Đóng", "Xuất"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        this.state.tabkey == 1 ? this.xport() : this.xport2();
      }
    });
  };

  handleCheck = async (day) => {
    const dayChose = this.state.listDay?.filter(
      (dayItem) => dayItem.id === Number(day)
    )[0].day;

    const newDate = new Date(dayChose);
    newDate.setHours(0, 0, 0, 0);
    const timestamp = newDate.getTime();
    const unixTimestamp = Math.floor(timestamp / 1000);

    this.setState({
      daySelect: Number(day),
      dayCheck: this.state.listDay?.filter(
        (dayItem) => dayItem.id === Number(day)
      )[0].day,
      timestampChose: unixTimestamp,
    });

    newDate.setHours(23, 59, 59);

    await this.getDatas(newDate);
  };

  dates = (current) => {
    var week = [];
    // Starting Monday not Sunday
    current.setDate(current.getDate() - current.getDay() + 1);
    current.setHours(0, 0, 0, 0);
    for (var i = 0; i < 7; i++) {
      week.push(new Date(current));
      current.setDate(current.getDate() + 1);
      current.setHours(0, 0, 0, 0);
    }
    return week;
  };

  setDate = (theDate) => {
    const week = this.dates(theDate);
    this.setState({
      start: week[0],
      term: week[week.length - 1],
    });
    // this.getMenu(week[0], week[week.length - 1]);
    return week;
  };

  stringFirstDate = (date) =>
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2);
  stringLastDate = (date) =>
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    date.getFullYear();
  //#endregion

  // Render
  RenderDayOption() {
    return (
      <>
        <Card.Title>Chọn ngày in sổ tài chính công khai</Card.Title>

        <div className="d-none d-md-flex justify-content-start my-3">
          {this.state.listDay.map((dayItem, i) => {
            let toDayIs = dayItem.id % 7;

            if (toDayIs === 0) {
              toDayIs = 7;
            }

            return (
              <div key={`day-key-${i}`} className="d-flex me-md-4 me-xl-5">
                <Form.Check
                  type="radio"
                  value={dayItem.id}
                  checked={this.state.daySelect === dayItem.id}
                  onChange={(e) => {
                    if (e.target.checked) {
                      this.handleCheck(e.target.value);
                    }
                  }}
                  name="day"
                  className="me-2"
                />
                {dayItem.day_name}
              </div>
            );
          })}
        </div>

        <Row className="my-3 d-md-none">
          <Col>
            {this.state.listDay
              .slice(0, Math.round(this.state.listDay.length / 2))
              .map((dayItem, i) => {
                let toDayIs = dayItem.id % 7;

                if (toDayIs === 0) {
                  toDayIs = 7;
                }

                return (
                  <Row key={`day-key-2-${i}`}>
                    <Col xs={1} md={1}>
                      <div className="text-center">
                        <Form.Check
                          type="radio"
                          value={dayItem.id}
                          checked={this.state.daySelect === dayItem.id}
                          onChange={(e) => {
                            if (e.target.checked) {
                              this.handleCheck(e.target.value);
                              return;
                            }
                          }}
                          name="dayM"
                        />
                      </div>
                    </Col>

                    <Col xs={10} md={6}>
                      {dayItem.day_name}
                    </Col>
                  </Row>
                );
              })}
          </Col>

          <Col>
            {this.state.listDay
              .slice(
                Math.round(
                  this.state.listDay.length / 2,
                  this.state.listDay.length
                )
              )
              .map((dayItem, i) => {
                let toDayIs = dayItem.id % 7;

                if (toDayIs === 0) {
                  toDayIs = 7;
                }

                return (
                  <Row key={`day-key-3-${i}`}>
                    <Col xs={1} md={1}>
                      <div className="text-center">
                        <Form.Check
                          type="radio"
                          value={dayItem.id}
                          checked={this.state.daySelect === dayItem.id}
                          onChange={(e) => {
                            if (e.target.checked) {
                              this.handleCheck(e.target.value);
                              return;
                            }
                          }}
                          name="dayM"
                        />
                      </div>
                    </Col>
                    <Col xs={10} md={6}>
                      {dayItem.day_name}
                    </Col>
                  </Row>
                );
              })}
          </Col>
        </Row>
      </>
    );
  }

  RenderPublicMarketTable() {
    return (
      <Tabs
        defaultActiveKey={this.state.tabkey}
        className="mb-3"
        onSelect={(k) => {
          this.setState({ tabkey: k });
        }}
      >
        <Tab eventKey={1} title="Mẫu số 1" style={{ overflowX: "scroll" }}>
          {this.renderTable1()}
        </Tab>

        <Tab eventKey={2} title="Mẫu số 2" style={{ overflowX: "scroll" }}>
          {this.renderTable2()}
        </Tab>
      </Tabs>
    );
  }

  // #region Sample 1
  renderTable1() {
    if (this.state.voucher?.ingredents?.length === 0) {
      return (
        <div className="d-flex justify-content-center mt-3">Chưa lưu gì đó</div>
      );
    }
    const storeItems =
      this.state.voucher?.ingredents?.filter(
        (item) => item.ingredent_type == 2
      ) || [];
    const marketItems =
      this.state.voucher?.ingredents?.filter(
        (item) => item.ingredent_type == 1
      ) || [];

    let bigArray = [];

    if (storeItems.length > marketItems.length) {
      bigArray = storeItems;
    } else {
      bigArray = marketItems;
    }

    let totalStoreMoney = 0;
    let totalMarketMoney = 0;

    return (
      <div>
        <Table bordered style={{ minWidth: 999 }}>
          <thead>
            <tr className="align-middle">
              <th colSpan={12}>{this.state.schoolInfo.school_name}</th>
            </tr>
            <tr className="text-center align-middle">
              <th colSpan={12}>BẢNG CÔNG KHAI CHI CHỢ</th>
            </tr>
            <tr className="text-center align-middle">
              <th colSpan={3}>
                {
                  this.state.listDay.filter(
                    (item) => item.id === this.state.daySelect
                  )[0]?.day_name
                }{" "}
                Ngày{" "}
                {this.state.listDay
                  .filter((item) => item.id === this.state.daySelect)[0]
                  ?.day.getDate()}{" "}
                Tháng{" "}
                {this.state.listDay
                  .filter((item) => item.id === this.state.daySelect)[0]
                  ?.day.getMonth() + 1}{" "}
                Năm{" "}
                {this.state.listDay
                  .filter((item) => item.id === this.state.daySelect)[0]
                  ?.day.getFullYear()}{" "}
              </th>
              <th colSpan={3}>
                <span>
                  Danh sách học sinh:&nbsp;{this.state.totalStudentsDate}
                </span>
              </th>
              <th colSpan={3}>
                Tổng số suất ăn:&nbsp;
                {this.state.totalMealsDate}
              </th>
              <th colSpan={3} className="text-end">
                Định mức chi:&nbsp;
                {this.state?.groups.map((item, index) => {
                  return (
                    <span key={`spendingNorms${index}`}>
                      <span>
                        {item.group_name}:{" "}
                        <CurrencyFormat
                          value={
                            parseFloat(item.fee_meal) +
                            parseFloat(item.fee_other)
                          }
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />{" "}
                        / Bé
                      </span>
                      <br />
                    </span>
                  );
                })}
              </th>
            </tr>
            <tr className="text-center">
              <th className="align-middle">STT</th>
              <th className="align-middle">Thực phẩm kho</th>
              <th className="align-middle">ĐVT</th>
              <th className="align-middle">Số lượng</th>
              <th className="align-middle">Đơn giá</th>
              <th className="align-middle">Thành tiền</th>
              <th className="align-middle">STT</th>
              <th className="align-middle">Thực phẩm chợ</th>
              <th className="align-middle">ĐVT</th>
              <th className="align-middle">Số lượng</th>
              <th className="align-middle">Đơn giá</th>
              <th className="align-middle">Thành tiền</th>
            </tr>
          </thead>
          <tbody>
            {bigArray.length !== 0 ? (
              bigArray.map((_, index) => {
                const storeItem = storeItems[index];
                const marketItem = marketItems[index];

                if (!storeItem) {
                  const intoMoney = Number(
                    Number(marketItem.ing_price) *
                      Number(marketItem.ing_quantity).toFixed(4)
                  ).toFixed(0);
                  totalMarketMoney += parseInt(intoMoney);

                  const qtyMarket = Number(marketItem.ing_quantity).toFixed(4);

                  return (
                    <tr className="align-middle" key={`bigArray-key-${index}`}>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="text-center">{index + 1}</td>
                      <td>{marketItem.ingredent_name}</td>
                      <td>{marketItem.unit_market_name}</td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={parseFloat(qtyMarket)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                      </td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={Number(marketItem.ing_price)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                      </td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={intoMoney}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                      </td>
                    </tr>
                  );
                }

                if (!marketItem) {
                  const intoMoney = Number(
                    Number(storeItem.ing_price) *
                      Number(storeItem.ing_quantity).toFixed(4)
                  ).toFixed(0);
                  totalStoreMoney += parseInt(intoMoney);

                  const qtyStore = Number(storeItem.ing_quantity).toFixed(4);

                  return (
                    <tr className="align-middle" key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td>{storeItem.ingredent_name}</td>
                      <td>{storeItem.unit_market_name}</td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={parseFloat(qtyStore)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                      </td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={Number(storeItem.ing_price)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                      </td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={intoMoney}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  );
                }
                const qtyStore = Number(storeItem.ing_quantity).toFixed(4);
                const intoMoneyStore = Number(
                  Number(storeItem.ing_price) * qtyStore
                ).toFixed(0);
                totalStoreMoney += parseInt(intoMoneyStore);

                const qtyMarket = Number(marketItem.ing_quantity).toFixed(4);
                const intoMoneyMarket = Number(
                  Number(marketItem.ing_price) * qtyMarket
                ).toFixed(0);
                totalMarketMoney += parseInt(intoMoneyMarket);
                return (
                  <tr className="align-middle" key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td>{storeItem.ingredent_name}</td>
                    <td>{storeItem.unit_market_name}</td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseFloat(qtyStore)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                      />
                    </td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={Number(storeItem.ing_price)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                      />
                    </td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={intoMoneyStore}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                      />
                    </td>
                    <td className="text-center">{index + 1}</td>
                    <td>{marketItem.ingredent_name}</td>
                    <td>{marketItem.unit_market_name}</td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseFloat(qtyMarket)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                      />
                    </td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={Number(marketItem.ing_price)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                      />
                    </td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={intoMoneyMarket}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="text-center align-middle" colSpan={12}>
                  Không có dữ liệu
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <th className="text-end align-middle" colSpan={5}>
                Cộng
              </th>
              <th className="text-end align-middle">
                <CurrencyFormat
                  value={Number(totalStoreMoney).toFixed(0)}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
              <th className="text-end align-middle" colSpan={5}>
                Cộng
              </th>
              <th className="text-end align-middle">
                <CurrencyFormat
                  value={Number(totalMarketMoney).toFixed(0)}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
            <tr>
              <th colSpan={6}></th>
              <th className="text-start align-middle" colSpan={5}>
                Tổng thu theo danh sách
              </th>
              <th className="text-end align-middle">
                <CurrencyFormat
                  value={parseInt(this.state.totalRevenueDate)}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
            <tr>
              <th colSpan={6}></th>
              <th className=" align-middle" colSpan={5}>
                Đã chi thực phẩm
              </th>
              <th className="text-end align-middle">
                <CurrencyFormat
                  value={this.state.totalIngredientExpensesDate}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
            <tr>
              <th colSpan={6}></th>
              <th colSpan={5} className="align-middle">
                Tổng tiền trả lại
              </th>
              <th className="text-end align-middle">
                <CurrencyFormat
                  value={this.state.totalRefundDate}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
            <tr>
              <th colSpan={6}></th>
              <th colSpan={5} className="align-middle">
                Tổng chi phí khác
              </th>
              <th className="text-end align-middle">
                <CurrencyFormat
                  value={this.state.totalFeeOtherDate}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
            <tr>
              <th colSpan={6}></th>
              <th className="align-middle" colSpan={5}>
                Thừa (thiếu) trong ngày
              </th>
              <th className="text-end align-middle">
                <CurrencyFormat
                  value={this.state.totalMoneyDay}
                  thousandSeparator={true}
                  allowNegative={true}
                  displayType="text"
                />
              </th>
            </tr>
          </tfoot>
        </Table>

        <Table bordered id="finance-day-book" className="d-none">
          <thead className="d-none">
            <tr>
              <th>{this.state.schoolInfo.school_name}</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
            </tr>
            <tr>
              <th>BẢNG CÔNG KHAI CHI CHỢ</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
            </tr>
            <tr>
              <th>
                {
                  this.state.listDay.filter(
                    (item) => item.id === this.state.daySelect
                  )[0]?.day_name
                }{" "}
                Ngày{" "}
                {this.state.listDay
                  .filter((item) => item.id === this.state.daySelect)[0]
                  ?.day.getDate()}{" "}
                Tháng{" "}
                {this.state.listDay
                  .filter((item) => item.id === this.state.daySelect)[0]
                  ?.day.getMonth() + 1}{" "}
                Năm{" "}
                {this.state.listDay
                  .filter((item) => item.id === this.state.daySelect)[0]
                  ?.day.getFullYear()}{" "}
              </th>
              <th>empty</th>
              <th>empty</th>
              <th>Danh sách học sinh:&nbsp;{this.state.totalStudentsDate}</th>
              <th>empty</th>
              <th>empty</th>
              <th>Tổng số suất ăn:&nbsp;{this.state.totalMealsDate}</th>
              <th>empty</th>
              <th>empty</th>
              <th>
                Định mức chi:<br></br>
                {this.state?.groups.map((item, index) => {
                  return (
                    <span key={`spendingNorms${index}`}>
                      <span>
                        {item.group_name}:&nbsp;
                        <CurrencyFormat
                          value={
                            parseFloat(item.fee_meal) +
                            parseFloat(item.fee_other)
                          }
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                        &nbsp;/ Bé
                      </span>
                      <br />
                    </span>
                  );
                })}
              </th>
              <th>empty</th>
              <th>empty</th>
            </tr>
            <tr>
              <th>STT</th>
              <th>Thực phẩm kho</th>
              <th>ĐVT</th>
              <th>Số lượng</th>
              <th>Đơn giá</th>
              <th>Thành tiền</th>
              <th>STT</th>
              <th>Thực phẩm chợ</th>
              <th>ĐVT</th>
              <th>Số lượng</th>
              <th>Đơn giá</th>
              <th>Thành tiền</th>
            </tr>
          </thead>
          <tbody className="d-none">
            {bigArray.length !== 0 ? (
              bigArray.map((_, index) => {
                const storeItem = storeItems[index];
                const marketItem = marketItems[index];
                let intoMoneyStore = 0;
                let intoMoneyMarket = 0;

                if (!storeItem) {
                  const qtyMarket = Number(marketItem.ing_quantity).toFixed(4);
                  intoMoneyMarket = Number(
                    Number(marketItem.ing_price) * qtyMarket
                  ).toFixed(0);

                  return (
                    <tr key={`item-excel-${index}`}>
                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>
                      <td>{index + 1}</td>
                      <td>{marketItem.ingredent_name}</td>
                      <td>{marketItem.unit_market_name}</td>
                      <td>
                        <CurrencyFormat
                          value={parseFloat(qtyMarket)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                      </td>
                      <td>
                        <CurrencyFormat
                          value={Number(marketItem.ing_price)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                      </td>
                      <td>
                        <CurrencyFormat
                          value={intoMoneyMarket}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                      </td>
                    </tr>
                  );
                }

                if (!marketItem) {
                  const qtyStore = Number(storeItem.ing_quantity).toFixed(4);
                  intoMoneyStore = Number(
                    Number(storeItem.ing_price) * qtyStore
                  ).toFixed(0);

                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{storeItem.ingredent_name}</td>
                      <td>{storeItem.unit_market_name}</td>
                      <td>
                        <CurrencyFormat
                          value={parseFloat(qtyStore)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                      </td>
                      <td>
                        <CurrencyFormat
                          value={Number(storeItem.ing_price)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                      </td>
                      <td>
                        <CurrencyFormat
                          value={intoMoneyStore}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                      </td>
                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>
                    </tr>
                  );
                }

                const qtyStore = Number(storeItem.ing_quantity).toFixed(4);
                intoMoneyStore = Number(
                  Number(storeItem.ing_price) * qtyStore
                ).toFixed(0);
                const qtyMarket = Number(marketItem.ing_quantity).toFixed(4);
                intoMoneyMarket = Number(
                  Number(marketItem.ing_price) * qtyMarket
                ).toFixed(0);

                return (
                  <tr className="align-middle" key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td>{storeItem.ingredent_name}</td>
                    <td>{storeItem.unit_market_name}</td>
                    <td>
                      <CurrencyFormat
                        value={parseFloat(qtyStore)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        value={Number(storeItem.ing_price)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        value={intoMoneyStore}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                      />
                    </td>
                    <td className="text-center">{index + 1}</td>
                    <td>{marketItem.ingredent_name}</td>
                    <td>{marketItem.unit_market_name}</td>
                    <td>
                      <CurrencyFormat
                        value={parseFloat(qtyMarket)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        value={Number(marketItem.ing_price)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        value={intoMoneyMarket}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={12}>Không có dữ liệu</td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <th>Cộng</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>
                <CurrencyFormat
                  value={Number(totalStoreMoney).toFixed(0)}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
              <th>Cộng</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>
                <CurrencyFormat
                  value={Number(totalMarketMoney).toFixed(0)}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
            <tr>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>Tổng thu theo danh sách</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>
                <CurrencyFormat
                  value={parseInt(this.state.totalRevenueDate)}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
            <tr>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>Đã chi thực phẩm</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>
                <CurrencyFormat
                  value={this.state.totalIngredientExpensesDate}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
            <tr>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>Tổng tiền trả lại</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>
                <CurrencyFormat
                  value={this.state.totalRefundDate}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
            <tr>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>Tổng chi phí khác</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>
                <CurrencyFormat
                  value={this.state.totalFeeOtherDate}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
            <tr>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>Thừa (thiếu) trong ngày</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>
                <CurrencyFormat
                  value={this.state.totalMoneyDay}
                  thousandSeparator={true}
                  allowNegative={true}
                  displayType="text"
                />
              </th>
            </tr>
          </tfoot>
        </Table>
      </div>
    );
  }

  // #region Sample 2
  renderTable2() {
    const storeItems =
      this.state.voucher?.ingredents?.filter(
        (item) => item.ingredent_type == 2
      ) || [];
    const marketItems =
      this.state.voucher?.ingredents?.filter(
        (item) => item.ingredent_type == 1
      ) || [];

    let sumMoneyMarket = 0;
    let sumMoneyStore = 0;

    let marketDailyNotBeenSaved = this.state.voucher || false;

    let haveSetting =
      this.state.settings?.some(
        (item) =>
          item.setting_date_start <= this.state.timestampChose &&
          item.setting_date_end >= this.state.timestampChose
      ) || false;

    if (marketDailyNotBeenSaved)
      return (
        <>
          <Table bordered>
            <thead>
              <tr className="align-middle">
                <th colSpan={4}>{this.state.schoolInfo.school_name}</th>
                <td colSpan={3} className="text-end">
                  {
                    this.state.listDay.filter(
                      (item) => item.id === this.state.daySelect
                    )[0]?.day_name
                  }{" "}
                  Ngày{" "}
                  {this.state.listDay
                    .filter((item) => item.id === this.state.daySelect)[0]
                    ?.day.getDate()}{" "}
                  Tháng{" "}
                  {this.state.listDay
                    .filter((item) => item.id === this.state.daySelect)[0]
                    ?.day.getMonth() + 1}{" "}
                  Năm{" "}
                  {this.state.listDay
                    .filter((item) => item.id === this.state.daySelect)[0]
                    ?.day.getFullYear()}
                </td>
              </tr>
              <tr className="text-center align-middle">
                <th colSpan={7}>BẢNG CÔNG KHAI CHI CHỢ</th>
              </tr>
              <tr className="align-middle">
                <td colSpan={4}>
                  <strong>Tổng số suất ăn: {this.state.totalMealsDate}</strong>
                </td>
                <td colSpan={3} className="text-end">
                  <strong>Định mức chi:</strong>{" "}
                  {this.state?.groups.map((item, index) => {
                    return (
                      <span key={`spendingNorms${index}`}>
                        <span>
                          {item.group_name}:{" "}
                          <CurrencyFormat
                            value={
                              parseFloat(item.fee_meal) +
                              parseFloat(item.fee_other)
                            }
                            thousandSeparator={true}
                            allowNegative={false}
                            isNumericString
                            displayType="text"
                          />{" "}
                          / Bé
                        </span>
                        <br />
                      </span>
                    );
                  })}
                </td>
              </tr>
              <tr>
                <th className="text-center">Thực đơn</th>
                <th className="text-center">STT</th>
                <th className="text-center">Tên thực phẩm</th>
                <th className="text-center">ĐVT</th>
                <th className="text-center">Số lượng</th>
                <th className="text-center">Đơn giá</th>
                <th className="text-center">Thành tiền</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={marketItems.length + storeItems.length + 3}>
                  {this.state.menu?.times?.map((time, timeIndex) => {
                    return (
                      <span key={`time${timeIndex}`}>
                        <strong>
                          Ăn {time.time_name}: <br />
                        </strong>
                        {this.state.menu?.days
                          ?.filter((e) => e.id_day == this.state.daySelect)[0]
                          ?.times?.filter(
                            (e) => e.time_name == time.time_name
                          )[0]
                          ?.foods?.map((food, foodIndex) => {
                            return (
                              <span key={`time${timeIndex}Food${foodIndex}`}>
                                - {food.food_name} <br />
                              </span>
                            );
                          })}
                      </span>
                    );
                  })}
                </td>
                <th colSpan={6}>Hàng chợ</th>
              </tr>
              {marketItems.map((item, index) => {
                const qtyMarket = Number(item.ing_quantity).toFixed(4);
                const price = Number(item.ing_price).toFixed(0);
                const intoMoneyMarket = Number(price * qtyMarket).toFixed(0);

                sumMoneyMarket += parseInt(intoMoneyMarket);

                return (
                  <tr key={`marketItems-key-${index}`}>
                    <td className="text-center">{index + 1}</td>
                    <td>{item.ingredent_name}</td>
                    <td>{item.unit_market_name}</td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseFloat(qtyMarket)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                      />
                    </td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseInt(price)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                      />
                    </td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseInt(intoMoneyMarket)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                      />
                    </td>
                  </tr>
                );
              })}
              <tr>
                <th colSpan={5} className="text-end">
                  Cộng chợ:
                </th>
                <th className="text-end">
                  <CurrencyFormat
                    value={parseInt(sumMoneyMarket)}
                    thousandSeparator={true}
                    allowNegative={false}
                    isNumericString
                    displayType="text"
                  />
                </th>
              </tr>
              <tr>
                <th colSpan={6}>Hàng kho</th>
              </tr>
              {storeItems.map((item, index) => {
                const qtyStore = Number(item.ing_quantity).toFixed(4);
                const priceStore = Number(item.ing_price).toFixed(0);
                const intoMoneyStore = Number(priceStore * qtyStore).toFixed(0);

                sumMoneyStore += parseInt(intoMoneyStore);

                return (
                  <tr key={`storeItemKey${index}`}>
                    <td className="text-center">{index + 1}</td>
                    <td>{item.ingredent_name}</td>
                    <td>{item.unit_market_name}</td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseFloat(qtyStore)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                      />
                    </td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseInt(priceStore)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                      />
                    </td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseInt(intoMoneyStore)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                      />
                    </td>
                  </tr>
                );
              })}

              <tr>
                <th></th>
                <th colSpan={5} className="text-end">
                  Cộng kho:
                </th>
                <th className="text-end">
                  <CurrencyFormat
                    value={parseInt(sumMoneyStore)}
                    thousandSeparator={true}
                    allowNegative={false}
                    isNumericString
                    displayType="text"
                  />
                </th>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colSpan={6}>Tổng cộng tiền thực phẩm</th>
                <th className="text-end">
                  <CurrencyFormat
                    value={this.state.totalIngredientExpensesDate}
                    thousandSeparator={true}
                    allowNegative={false}
                    isNumericString
                    displayType="text"
                  />
                </th>
              </tr>
              <tr>
                <td colSpan={6}>
                  <strong>Tổng thu trong ngày</strong>
                </td>
                <th className="text-end">
                  <CurrencyFormat
                    value={parseInt(this.state.totalRevenueDate)}
                    thousandSeparator={true}
                    allowNegative={false}
                    isNumericString
                    displayType="text"
                  />
                </th>
              </tr>
              <tr>
                <td colSpan={6}>
                  <strong>Tổng tiền trả lại</strong>&nbsp;
                </td>
                <th className="text-end">
                  <CurrencyFormat
                    value={this.state.totalRefundDate}
                    thousandSeparator={true}
                    allowNegative={false}
                    isNumericString
                    displayType="text"
                  />
                </th>
              </tr>
              <tr>
                <th colSpan={6}>Tổng chi trong ngày</th>
                <th className="text-end">
                  <CurrencyFormat
                    value={this.state.totalIngredientExpensesDate}
                    thousandSeparator={true}
                    allowNegative={false}
                    isNumericString
                    displayType="text"
                  />
                </th>
              </tr>
              <tr>
                <th colSpan={6}>Tổng chi phí khác trong ngày</th>
                <th className="text-end">
                  <CurrencyFormat
                    value={this.state.totalFeeOtherDate}
                    thousandSeparator={true}
                    allowNegative={false}
                    isNumericString
                    displayType="text"
                  />
                </th>
              </tr>
              <tr>
                <th colSpan={6}>Thừa (thiếu) trong ngày</th>
                <th className="text-end">
                  <CurrencyFormat
                    value={this.state.totalMoneyDay}
                    thousandSeparator={true}
                    allowNegative={true}
                    displayType="text"
                  />
                </th>
              </tr>
              <tr>
                <th colSpan={6}>
                  Lũy kế tháng{" "}
                  {this.state.listDay
                    .filter((item) => item.id === this.state.daySelect)[0]
                    ?.day?.getMonth() + 1}
                </th>
                <th className="text-end">
                  <CurrencyFormat
                    value={this.state.totalMoneyMonth}
                    thousandSeparator={true}
                    allowNegative={true}
                    displayType="text"
                  />
                </th>
              </tr>
            </tfoot>
          </Table>

          <Table bordered id="finance-day-book2" className="d-none">
            <thead>
              <tr>
                <th colSpan={4}>{this.state.schoolInfo.school_name}</th>
                <td colSpan={3}>
                  {
                    this.state.listDay.filter(
                      (item) => item.id === this.state.daySelect
                    )[0]?.day_name
                  }{" "}
                  Ngày{" "}
                  {this.state.listDay
                    .filter((item) => item.id === this.state.daySelect)[0]
                    ?.day.getDate()}{" "}
                  Tháng{" "}
                  {this.state.listDay
                    .filter((item) => item.id === this.state.daySelect)[0]
                    ?.day.getMonth() + 1}{" "}
                  Năm{" "}
                  {this.state.listDay
                    .filter((item) => item.id === this.state.daySelect)[0]
                    ?.day.getFullYear()}
                </td>
              </tr>
              <tr>
                <th colSpan={7}>BẢNG CÔNG KHAI CHI CHỢ</th>
              </tr>
              <tr>
                <td colSpan={4}>
                  Tổng số suất ăn:&nbsp;{this.state.totalMealsDate}
                </td>
                <td colSpan={3}>
                  Định mức chi: <br />
                  {this.state?.groups.map((item, index) => {
                    return (
                      <span key={`groupKey1${index}`}>
                        {item.group_name}:&nbsp;
                        <CurrencyFormat
                          value={
                            parseFloat(item.fee_meal) +
                            parseFloat(item.fee_other)
                          }
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                        &nbsp;/ Bé
                        {index + 1 != this.state.groups?.length ? <br /> : null}
                      </span>
                    );
                  })}
                </td>
              </tr>
              <tr>
                <th>Thực đơn</th>
                <th>STT</th>
                <th>Tên thực phẩm</th>
                <th>ĐVT</th>
                <th>Số lượng</th>
                <th>Đơn giá</th>
                <th>Thành tiền</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {this.state.menu?.times?.map((time, timeIndex) => {
                    return (
                      <span key={`timeKey${timeIndex}`}>
                        <strong>
                          Ăn {time.time_name}: <br />
                        </strong>
                        {this.state.menu?.days
                          ?.filter((e) => e.id_day == this.state.daySelect)[0]
                          ?.times?.filter(
                            (e) => e.time_name == time.time_name
                          )[0]
                          ?.foods?.map((food, foodIndex) => {
                            return (
                              <span
                                key={`timeKey${timeIndex}FoodKey${foodIndex}`}
                              >
                                - {food.food_name} <br />
                              </span>
                            );
                          })}
                      </span>
                    );
                  })}
                </td>
                <th>Hàng chợ</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
              </tr>
              {marketItems.map((item, index) => {
                const qtyMarket = Number(item.ing_quantity).toFixed(4);
                const price = Number(item.ing_price).toFixed(0);
                const intoMoneyMarket = Number(price * qtyMarket).toFixed(0);
                return (
                  <tr key={`marketItemKey${index}`}>
                    <td></td>
                    <td>{index + 1}</td>
                    <td>{item.ingredent_name}</td>
                    <td>{item.unit_market_name}</td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseFloat(qtyMarket)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                      />
                    </td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseInt(price)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                      />
                    </td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseInt(intoMoneyMarket)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                      />
                    </td>
                  </tr>
                );
              })}
              <tr>
                <th></th>
                <th className="text-end">Cộng chợ:</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th className="text-end">
                  <CurrencyFormat
                    value={parseInt(sumMoneyMarket)}
                    thousandSeparator={true}
                    allowNegative={false}
                    isNumericString
                    displayType="text"
                  />
                </th>
              </tr>
              <tr>
                <th></th>
                <th>Hàng kho</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
              </tr>
              {storeItems.map((item, index) => {
                const qtyStore = Number(item.ing_quantity).toFixed(4);
                const priceStore = Number(item.ing_price).toFixed(0);
                const intoMoneyStore = Number(priceStore * qtyStore).toFixed(0);
                return (
                  <tr key={`storeItemKey1${index}`}>
                    <td></td>
                    <td>{index + 1}</td>
                    <td>{item.ingredent_name}</td>
                    <td>{item.unit_market_name}</td>
                    <td>
                      <CurrencyFormat
                        value={parseFloat(qtyStore)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        value={parseInt(priceStore)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        value={parseInt(intoMoneyStore)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                      />
                    </td>
                  </tr>
                );
              })}
              <tr>
                <th></th>
                <th>Cộng kho:</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>
                  <CurrencyFormat
                    value={parseInt(sumMoneyStore)}
                    thousandSeparator={true}
                    allowNegative={false}
                    isNumericString
                    displayType="text"
                  />
                </th>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>Tổng cộng tiền thực phẩm</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>
                  <CurrencyFormat
                    value={this.state.totalIngredientExpensesDate}
                    thousandSeparator={true}
                    allowNegative={false}
                    isNumericString
                    displayType="text"
                  />
                </th>
              </tr>
              <tr>
                <td>Tổng thu trong ngày</td>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>
                  <CurrencyFormat
                    value={parseInt(this.state.totalRevenueDate)}
                    thousandSeparator={true}
                    allowNegative={false}
                    isNumericString
                    displayType="text"
                  />
                </th>
              </tr>
              <tr>
                <th>Tổng tiền trả lại</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>
                  <CurrencyFormat
                    value={this.state.totalRefundDate}
                    thousandSeparator={true}
                    allowNegative={false}
                    isNumericString
                    displayType="text"
                  />
                </th>
              </tr>
              <tr>
                <th>Tổng chi trong ngày</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>
                  <CurrencyFormat
                    value={this.state.totalIngredientExpensesDate}
                    thousandSeparator={true}
                    allowNegative={false}
                    isNumericString
                    displayType="text"
                  />
                </th>
              </tr>
              <tr>
                <th>Tổng chi phí khác trong ngày</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>
                  <CurrencyFormat
                    value={this.state.totalFeeOtherDate}
                    thousandSeparator={true}
                    allowNegative={false}
                    isNumericString
                    displayType="text"
                  />
                </th>
              </tr>
              <tr>
                <th>Thừa (thiếu) trong ngày</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>
                  <CurrencyFormat
                    value={this.state.totalMoneyDay}
                    thousandSeparator={true}
                    allowNegative={true}
                    displayType="text"
                  />
                </th>
              </tr>
              <tr>
                <th>
                  Lũy kế tháng{" "}
                  {this.state.listDay
                    .filter((item) => item.id === this.state.daySelect)[0]
                    ?.day?.getMonth() + 1}
                </th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>
                  <CurrencyFormat
                    value={this.state.totalMoneyMonth}
                    thousandSeparator={true}
                    allowNegative={true}
                    displayType="text"
                  />
                </th>
              </tr>
            </tfoot>
          </Table>
        </>
      );
    else if (!haveSetting)
      return <h6 className="text-muted">Không tìm thấy cài đặt!</h6>;
    else return <h6 className="text-muted">Vui lòng lưu Phiếu đi chợ ngày!</h6>;
  }

  //#region  render
  render() {
    return (
      <Card>
        <Card.Header>
          <div className="d-block d-md-flex justify-content-between">
            <div className="d-block d-sm-flex mb-3 mb-sm-0">
              <Card.Title className="me-3 pt-1">
                <i className="fas fa-list me-1"></i> Sổ tài chính công khai
                Tháng {this.state.start.getMonth() + 1} (Tuần{" "}
                {this.state.weekInMonth}){" "}
                {this.stringFirstDate(this.state.start)} -{" "}
                {this.stringLastDate(this.state.term)}
              </Card.Title>

              <div className="d-flex align-items-center">
                <DatePickerCustom
                  startDate={this.state.start}
                  endDate={this.state.term}
                  onChange={(dateChoice) => {
                    const firstDayOfWeek = new Date(dateChoice);
                    const week = this.setDate(firstDayOfWeek);

                    const newListDay = DAYS_OF_WEEK.map((day, index) => {
                      return {
                        id: day.id,
                        day_name: day.day_name,
                        day: week[index],
                      };
                    });

                    this.setState({
                      weekInMonth: (0 | (week[0].getDate() / 7)) + 1,
                      listDay: newListDay,
                    });

                    const date = newListDay.filter(
                      (day) => day.id === this.state.daySelect
                    )[0].day;
                    date.setHours(0, 0, 0);

                    const dateTimestamp = date / 1000;

                    this.setState({ timestampChose: dateTimestamp });

                    this.getDatas(date);
                    this.getMenu(
                      newListDay.find((day) => day.id === 1).day,
                      newListDay.find((day) => day.id === 7).day
                    );
                  }}
                  showWeekNumbers
                  dateFormat="yyyy-MM-dd"
                  calendarClassName="custom-calendar"
                />
              </div>
            </div>

            <Button
              variant="success"
              size="sm"
              onClick={() => {
                this.handleExport();
              }}
              disabled={
                !this.state.voucher ||
                this.state.voucher?.ingredents?.length === 0
              }
            >
              Xuất file <i className="fa-solid fa-file-export"></i>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          {this.RenderDayOption()}

          {!this.state.isLoading ? (
            this.state.voucher ? (
              this.RenderPublicMarketTable()
            ) : (
              <div className="d-flex justify-content-center mt-3">
                Không tìm thấy phiếu đi chợ ngày.
              </div>
            )
          ) : (
            <div className="d-flex justify-content-center align-items-center mt-5">
              <div
                className="spinner-border text-primary"
                role="status"
                style={{ width: "3rem", height: "3rem" }}
              >
                <span className="sr-only">Đang tải sổ tài chính...</span>
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
    );
  }
}

export default FinanceDayBook;
