import React, { Component } from "react";
import { Button, Form, Modal, Row, Col, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ClassCreate from "../classV2_del/ClassCreate";
import moment from "moment/moment";
import Check from "../other/Check";
import api from "../../../helper/axiosInstance";

class StudentEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      type: "",
      listUser: [],
      listclass: [],
      address: {
        number: "",
        group: "",
        zone: "",
        street: "",
        ward: "",
        district: "",
        city: "",
      },
      student_birthday: "",
      start_at:
        props.obj.student[0]?.process[props.obj.student[0]?.process.length - 1]
          ?.start_at ?? "",
      end_at:
        props.obj.student[0]?.process[props.obj.student[0]?.process.length - 1]
          ?.end_at ?? "",

      parent: {
        name: "",
        job: "",
        phone: "",
      },
      mother: {
        name: "",
        job: "",
        phone: "",
      },
      data_: [],
      check: "",
      tabkey: 1,
      listEthnic: [],
    };
  }

  async componentDidMount() {
    this.getEthnic();
    this.getlistuser();
  }
  getEthnic = () => {
    api
      .get("/viewEthnic")
      .then((res) => this.setState({ listEthnic: res.data.data }));
  };
  getlistclass = () => {
    api
      .get(`/viewClassRoom`)
      .then((res) => {
        if (res.data.status) {
          this.setState({
            listclass: res.data.data,
          });
        }
      })
      .catch((error) => {});
  };
  handleInput = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    if (field === "student_status") {
      this.props.ableForm.value = value == 1 ? false : true;
      this.setState({
        student_status: value,
      });
    }

    if (field === "is_meal") {
      value = event.target.checked;
      this.setState({ check: event.target.checked });
    }
    if (field === "student_status") {
      if (event.target.value > 2 || event.target.value == 0) {
        toast("Trạng thái không đúng!", { type: "error", autoClose: 1000 });
      }
    }
    this.props.obj.student[0][field] = value;
  };
  handleInput1 = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    this.props.obj.student[0].address[field] = value;
  };
  handleInputAdreess = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    this.props.obj.student[0].address[field] = value;
  };
  handleInputParent = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    this.props.obj.student[0].parent[field] = value;
  };
  handleInputMother = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    this.props.obj.student[0].mother[field] = value;
  };
  handleSubmit = (event) => {
    event.preventDefault();
    let student_birthday_edit = 0;
    let start_at_edit = 0;
    let end_at_edit = 0;

    if (Number(this.state.student_birthday) < 1000) {
      student_birthday_edit = this.props.obj.student[0].student_birthday;
    } else {
      student_birthday_edit = this.state.student_birthday;
    }
    if (Number(this.state.start_at) < 1000) {
      start_at_edit =
        this.props.obj.student[0].process[
          this.props.obj.student[0].process.length - 1
        ]?.start_at;
    } else {
      start_at_edit = this.state.start_at;
    }
    if (Number(this.state.end_at) < 1000) {
      end_at_edit =
        this.props.obj.student[0].process[
          this.props.obj.student[0].process.length - 1
        ]?.end_at;
    } else {
      end_at_edit = this.state.end_at;
    }

    let student_name = this.props.obj.student[0].student_name;
    let student_gender = this.props.obj.student[0].student_gender;
    let is_meal = 0;
    if (this.props.obj.student[0]?.is_meal === true) {
      is_meal = 1;
    } else {
      is_meal = 0;
    }
    if (
      start_at_edit >
      new Date(new Date().setFullYear(new Date().getFullYear() + 10)) / 1000
    ) {
      toast("Ngày nhập học không hợp lệ", { type: "error", autoClose: 1000 });
    } else
      api
        .post(
          `/updateStudent?id_student=${
            this.props.obj.student[0]?.id
          }&student_name=${student_name}&student_gender=${student_gender}&student_birthday=${student_birthday_edit}&id_class=${
            this.props.obj.student[0].id_class
          }&student_identifier=${
            this.props.obj.student[0].student_identifier
          }&student_note=${
            this.props.obj.student[0]?.student_note
          }&is_meal=${is_meal}&address=${JSON.stringify(
            this.props.obj.student[0].address
          )}&parent=${JSON.stringify(
            this.props.obj.student[0].parent
          )}&mother=${JSON.stringify(
            this.props.obj.student[0].mother
          )}&student_ethnic=${
            this.props.obj.student[0].student_ethnic
          }&student_population=${
            this.props.obj.student[0].student_population
          }&student_contact=${
            this.props.obj.student[0].student_contact
          }&student_status=${
            this.props.obj.student[0].student_status
          }&start_at=${start_at_edit}&end_at=${end_at_edit}`
        )
        .then((res) => {
          if (res.data.status === true) {
            this.setState({ student_birthday: "" });
            this.setState({ start_at: "" });
            this.setState({ end_at: "" });

            this.props.onHide();
            toast(res.data.data.mess, { type: "success", autoClose: 1000 });
          } else {
            toast(res.data.data.mess, { type: "error", autoClose: 1000 });
          }
        });
  };

  dataClass() {
    if (this.state.listclass.length > 0) {
      return this.state.listclass.map((item, i) => {
        return (
          <option key={i} value={item.id}>
            {item.class_name}
          </option>
        );
      });
    } else {
      return this.props.listclass.map((item, i) => {
        return (
          <option key={i} value={item.id}>
            {item.class_name}
          </option>
        );
      });
    }
  }

  getlistuser = () => {
    api
      .get("/showAllUser")
      .then((res) => {
        if (res.data.status) {
          this.setState({
            listUser: res.data.data,
          });
        }
      })
      .catch((error) => {});
  };

  formclose = () => {
    this.setState({ show: false });
    this.getlistclass();
  };

  renderClassAdd() {
    return (
      <ClassCreate
        listuser={this.state.listUser}
        PT={this.state.type}
        show={this.state.show}
        close={this.formclose}
      />
    );
  }
  getDate = (data) => {
    let date = new Date(data * 1000);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };
  getDate11 = (data) => {
    let date = new Date(data * 1000);
    return ` ${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
  };

  render() {
    var dataStudent = this.props.obj;
    // console.log(dataStudent.student[0]?.process[0]?.start_at);
    return (
      <>
        <Modal
          {...this.props}
          size="md"
          backdrop="static"
          keyboard={false}
          id="addStudentModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {/* <i className="fa-solid fa-plus"></i> */}
              <p className="text-center"> Sửa học sinh</p>
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Tabs
                defaultActiveKey={1}
                // activeKey={this.state.tabkey}
                onSelect={(k) => {
                  this.setState({ tabkey: k });
                }}
                className="mb-3"
              >
                <Tab key={1} title="Thông tin học sinh" eventKey={1}>
                  <Row>
                    <Col>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={
                            dataStudent.student[0]?.student_identifier
                          }
                          onChange={this.handleInput}
                          name="student_identifier"
                          placeholder="Mã định danh bộ GD&ĐT"
                          // required
                        />

                        <Form.Label>
                          Mã định danh bộ GD&ĐT<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={
                            dataStudent.student[0]?.student_population == "null"
                              ? ""
                              : dataStudent.student[0]?.student_population
                          }
                          onChange={this.handleInput}
                          name="student_population"
                          placeholder="Mã định danh dân cư"
                          // required
                        />
                        <Form.Label>
                          Mã định danh dân cư<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={8}>
                      {new Check().permission(["13"]) ? <br /> : null}
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={dataStudent.student[0]?.student_name}
                          onChange={this.handleInput}
                          name="student_name"
                          placeholder="Tên học sinh"
                          required
                        />
                        <Form.Label>
                          Tên học sinh<i className="text-danger">*</i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={4}>
                      {new Check().permission(["13"]) ? (
                        <Form.Group className="text-end">
                          <Link
                            className="fst-italic"
                            onClick={() => {
                              this.setState({
                                show: true,
                                type: "TM",
                              });
                            }}
                          >
                            +Thêm lớp
                          </Link>
                        </Form.Group>
                      ) : null}
                      <Form.Floating className="mb-3">
                        <Form.Select
                          defaultValue={dataStudent.student[0]?.id_class}
                          onChange={this.handleInput}
                          name="id_class"
                          required
                        >
                          <option value="">--Chọn--</option>
                          {this.dataClass()}
                        </Form.Select>
                        <Form.Label>
                          Lớp<i className="text-danger">*</i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <Form.Select
                          defaultValue={dataStudent.student[0]?.student_gender}
                          onChange={this.handleInput}
                          name="student_gender"
                          // required
                        >
                          <option value="">--Chọn--</option>
                          <option value="1">Nam</option>
                          <option value="2">Nữ</option>
                          <option value="3">Khác</option>
                        </Form.Select>
                        <Form.Label>
                          Giới tính<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <input
                          className="form-control"
                          // required
                          type="date"
                          name="student_birthday"
                          // defaultValue={this.getDate(dataStudent.student[0]?.student_birthday)}
                          defaultValue={moment(
                            new Date(
                              dataStudent.student[0]?.student_birthday * 1000
                            )
                          ).format("YYYY-MM-DD")}
                          // onChange={this.handleInput}
                          onChange={(event) =>
                            this.setState({
                              student_birthday:
                                new Date(event.target.value) / 1000,
                            })
                          }
                        />
                        <Form.Label>
                          Ngày sinh<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <Form.Select
                          defaultValue={dataStudent.student[0]?.student_ethnic}
                          onChange={this.handleInput}
                          name="student_ethnic"
                          // required
                        >
                          <option value="">--Chọn--</option>
                          {this.state.listEthnic.length > 0
                            ? this.state.listEthnic.map((ethnic_) => {
                                return (
                                  <option value={ethnic_.id}>
                                    {ethnic_.ethnic_name}
                                  </option>
                                );
                              })
                            : null}
                        </Form.Select>
                        <Form.Label>
                          Dân tộc<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={8}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={
                            dataStudent.student[0]?.student_note ===
                              "undefined" ||
                            dataStudent.student[0]?.student_note === "null"
                              ? ""
                              : dataStudent.student[0]?.student_note
                          }
                          onChange={this.handleInput}
                          name="student_note"
                          placeholder="Ghi chú"
                          // required
                        />
                        <Form.Label>
                          Ghi chú<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={
                            dataStudent.student[0]?.student_contact === "null"
                              ? ""
                              : dataStudent.student[0]?.student_contact
                          }
                          onChange={this.handleInput}
                          name="student_contact"
                          // id="student_name"
                          placeholder="SĐT liên hệ"
                          // required
                        />
                        <Form.Label>
                          SĐT liên hệ<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <Form.Select
                          defaultValue={dataStudent.student[0]?.student_status}
                          onChange={this.handleInput}
                          name="student_status"
                          required
                        >
                          <option value="1">Đang học</option>
                          <option value="2">Tạm nghỉ</option>
                        </Form.Select>

                        <Form.Label>
                          Trạng thái<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <input
                          className="form-control"
                          // required
                          type="date"
                          name="start_at"
                          defaultValue={moment(
                            new Date(
                              dataStudent.student[0]?.process[
                                dataStudent.student[0]?.process.length - 1
                              ]?.start_at * 1000
                            )
                          ).format("YYYY-MM-DD")}
                          onChange={(event) =>
                            this.setState({
                              start_at: new Date(event.target.value) / 1000,
                            })
                          }
                          // required={this.state.student_status == "1" ? true : false}
                          disabled={this.props.ableForm.value}
                          required={
                            this.props.ableForm.value == "1" ? false : true
                          }
                        />
                        <Form.Label>
                          Ngày nhập học
                          {this.props.ableForm.value == "1" ? (
                            ""
                          ) : (
                            <i className="text-danger">*</i>
                          )}
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <input
                          type="date"
                          className="form-control"
                          // required
                          name="end_at"
                          onChange={(event) =>
                            this.setState({
                              end_at: new Date(event.target.value) / 1000,
                            })
                          }
                          defaultValue={
                            dataStudent.student[0]?.process[
                              dataStudent.student[0]?.process.length - 1
                            ]?.end_at * 1000
                              ? moment(
                                  new Date(
                                    dataStudent.student[0]?.process[
                                      dataStudent.student[0]?.process.length - 1
                                    ]?.end_at * 1000
                                  )
                                ).format("YYYY-MM-DD")
                              : null
                          }
                          disabled={!this.props.ableForm.value}
                          required={
                            this.props.ableForm.value == "1" ? true : false
                          }
                        />
                        <Form.Label>
                          Ngày nghỉ học
                          {this.props.ableForm.value == "2" ? (
                            ""
                          ) : (
                            <i className="text-danger">*</i>
                          )}
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                  </Row>

                  <Form.Check
                    className="mb-3"
                    type="checkbox"
                    label="Không ăn ở trường"
                    // defaultValue={true}
                    name="is_meal"
                    onChange={this.handleInput}
                    // checked={this.state.check}
                    checked={dataStudent.student[0]?.is_meal}
                  />
                </Tab>
                <Tab key={2} title="Thông tin gia đình" eventKey={2}>
                  <Row>
                    <Col md={6}>
                      <p style={{ fontWeight: "bold" }}>Thông tin cha</p>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={
                            dataStudent.student[0]?.parent?.name ===
                              "undefined" ||
                            dataStudent.student[0]?.parent?.name === "null"
                              ? ""
                              : dataStudent.student[0]?.parent?.name
                          }
                          onChange={this.handleInputParent}
                          name="name"
                          placeholder="Họ và tên"
                        />
                        <Form.Label>
                          Họ và tên<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>

                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={
                            dataStudent.student[0]?.parent?.job ===
                              "undefined" ||
                            dataStudent.student[0]?.parent?.job === "null"
                              ? ""
                              : dataStudent.student[0]?.parent?.job
                          }
                          onChange={this.handleInputParent}
                          name="job"
                          placeholder="Nghề nghiệp"
                        />
                        <Form.Label>
                          Nghề nghiệp<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={
                            dataStudent.student[0]?.parent?.phone ===
                              "undefined" ||
                            dataStudent.student[0]?.parent?.phone === "null"
                              ? ""
                              : dataStudent.student[0]?.parent?.phone
                          }
                          onChange={this.handleInputParent}
                          name="phone"
                          placeholder="Số điện thoại"
                        />
                        <Form.Label>
                          Số điện thoại<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={6}>
                      <p style={{ fontWeight: "bold" }}>Thông tin mẹ</p>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={
                            dataStudent.student[0]?.mother?.name ===
                              "undefined" ||
                            dataStudent.student[0]?.mother?.name === "null"
                              ? ""
                              : dataStudent.student[0]?.mother?.name
                          }
                          onChange={this.handleInputMother}
                          name="name"
                          placeholder="Họ và tên"
                        />
                        <Form.Label>
                          Họ và tên<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>

                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={
                            dataStudent.student[0]?.mother?.job ===
                              "undefined" ||
                            dataStudent.student[0]?.mother?.job === "null"
                              ? ""
                              : dataStudent.student[0]?.mother?.job
                          }
                          onChange={this.handleInputMother}
                          name="job"
                          placeholder="Nghề nghiệp"
                        />

                        <Form.Label>
                          Nghề nghiệp<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={
                            dataStudent.student[0]?.mother?.phone ===
                              "undefined" ||
                            dataStudent.student[0]?.mother?.phone === "null"
                              ? ""
                              : dataStudent.student[0]?.mother?.phone
                          }
                          onChange={this.handleInputMother}
                          name="phone"
                          placeholder="Số điện thoại"
                        />
                        <Form.Label>
                          Số điện thoại<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                  </Row>
                  <p style={{ fontWeight: "bold" }}>Địa chỉ gia đình</p>
                  <Row>
                    <Col md={3}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={
                            dataStudent.student[0]?.address?.number ===
                              "undefined" ||
                            dataStudent.student[0]?.address?.number === "null"
                              ? ""
                              : dataStudent.student[0]?.address?.number
                          }
                          onChange={this.handleInput1}
                          name="number"
                          // id="student_name"
                          placeholder="Số nhà"
                        />
                        <Form.Label>
                          Số nhà<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={2}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={
                            dataStudent.student[0]?.address?.group ===
                              "undefined" ||
                            dataStudent.student[0]?.address?.group === "null"
                              ? ""
                              : dataStudent.student[0]?.address?.group
                          }
                          onChange={this.handleInputAdreess}
                          name="group"
                          // id="student_name"
                          placeholder="Tổ"
                        />
                        <Form.Label>
                          Tổ<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={3}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={
                            dataStudent.student[0]?.address?.zone ===
                              "undefined" ||
                            dataStudent.student[0]?.address?.zone === "null"
                              ? ""
                              : dataStudent.student[0]?.address?.zone
                          }
                          onChange={this.handleInputAdreess}
                          name="zone"
                          // id="student_name"
                          placeholder="Khu vực"
                        />
                        <Form.Label>
                          Khu vực<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={
                            dataStudent.student[0]?.address?.street ===
                              "undefined" ||
                            dataStudent.student[0]?.address?.street === "null"
                              ? ""
                              : dataStudent.student[0]?.address?.street
                          }
                          onChange={this.handleInputAdreess}
                          name="street"
                          // id="student_name"
                          placeholder="Đường"
                        />
                        <Form.Label>
                          Đường<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={
                            dataStudent.student[0]?.address?.wardt ===
                              "undefined" ||
                            dataStudent.student[0]?.address?.wardt === "null"
                              ? ""
                              : dataStudent.student[0]?.address?.wardt
                          }
                          onChange={this.handleInputAdreess}
                          name="ward"
                          // id="student_name"
                          placeholder="Phường"
                        />
                        <Form.Label>
                          Phường<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={
                            dataStudent.student[0]?.address?.district ===
                              "undefined" ||
                            dataStudent.student[0]?.address?.district === "null"
                              ? ""
                              : dataStudent.student[0]?.address?.district
                          }
                          onChange={this.handleInputAdreess}
                          name="district"
                          placeholder="Quận"
                        />
                        <Form.Label>
                          Quận<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={
                            dataStudent.student[0]?.address?.city ===
                              "undefined" ||
                            dataStudent.student[0]?.address?.city === "null"
                              ? ""
                              : dataStudent.student[0]?.address?.city
                          }
                          onChange={this.handleInputAdreess}
                          name="city"
                          placeholder="Tỉnh,TP"
                        />
                        <Form.Label>
                          Tỉnh, TP<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            </Modal.Body>

            <Modal.Footer>
              <Button size="sm" variant="secondary" onClick={this.props.onHide}>
                <i className="fa-solid fa-times"></i> Đóng
              </Button>
              {new Check().permission(["20"]) ? (
                <Button size="sm" variant="success" type="submit">
                  <i className="fa-solid fa-check"></i> Lưu
                </Button>
              ) : null}
            </Modal.Footer>
          </Form>
        </Modal>
        {this.renderClassAdd()}
      </>
    );
  }
}

export default StudentEdit;
