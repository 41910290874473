import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import DataTables from "../../datatable/DataTables";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import Select from "react-select";
import { dateFormatter } from "../../utils/helpers";
import { lastDayOfMonth } from "../../../../helper/times";
import { toSlug } from "../../utils/helpers";
import api from "../../../../helper/axiosInstance";

const errorTextStyle = {
  fontSize: 13,
  color: "red",
  marginTop: 6,
};

const ClassBySubjectDetailAdd = ({
  show,
  onHide,
  listStudents,
  studyGroupId,
  listStudentIdsInGroup,
  subject,
}) => {
  const [detail, setDetail] = useState({
    id_student: "",
    tuition_fee: parseInt(subject.subject_price, 10),
    reduce_money: "",
    notes: "",
  });

  const [errorMessage, setErrorMessage] = useState({
    id_student: "",
    tuition_fee: "",
    reduce_money: "",
    notes: "",
  });

  const [listClass, setListClass] = useState([]);
  const [choiceClass, setChoiceClass] = useState({});
  const [filterText, setFilterText] = useState("");
  const [ids, setIds] = useState([]);
  const [isTableSelectable, setisTableSelectable] = useState(true);
  const [listclassID, setlistclassID] = useState([]);
  const [studentsSearch, setstudentsSearch] = useState([]);

  useEffect(() => {
    getClassRoom(
      new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0)) / 1000,
      lastDayOfMonth(new Date().getFullYear(), new Date().getMonth() + 1) / 1000
    );
  }, []);

  const getClassbyId = (id) => {
    api.get(`/student-shows?id_class=${id}`).then((res) => {
      setlistclassID(res.data.data?.students);
      setisTableSelectable(true);
      setIds([]);
      filterStudent(id, "", res.data.data?.students);
    });
  };

  const filterStudent = (idclass, student_name, data) => {
    const filteredStudents = data?.filter(
      (student) =>
        (idclass == 0 || student.id_class == idclass) &&
        toSlug(student?.student_name)?.includes(toSlug(student_name?.trim()))
    );
    setFilterText(student_name);
    setisTableSelectable(false);
    setstudentsSearch(filteredStudents);
  };

  const getClassRoom = (start, end) => {
    api
      .get(`/classroom-shows?start_date=${parseInt(start)}&end_date=${end}`)
      .then((res) => {
        let arr = [];

        res.data.data?.classrooms?.map((item) => {
          arr.push({ value: item.id, label: item.class_name });
          return item;
        });

        setChoiceClass(arr[0]);
        getClassbyId(arr[0]?.value);
        setListClass(arr);
      });
  };

  const createClassBySubjectDetail = async () => {
    setErrorMessage({
      id_student: "",
      tuition_fee: "",
      reduce_money: "",
      notes: "",
    });

    await api({
      method: "post",
      url: "class-subject-detail-create",
      data: {
        id_study_group: studyGroupId,
        classesBySubjectDetail: ids?.map((e) => {
          return {
            id_student: e.id,
            tuition_fee: detail.tuition_fee,
            reduce_money: detail.reduce_money ?? 0,
            notes: detail.notes ?? null,
          };
        }),
      },
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        toast(res.data.messages, {
          type: "success",
          autoClose: 1000,
        });
        onHide(true);
      })
      .catch((err) => {
        toast("Thêm thất bại", {
          type: "error",
          autoClose: 1000,
        });
        setErrorMessage(err.response.data.messages);
      });
  };
  // API
  const onSelectedRowsChange = (rows) => {
    setIds(rows);
  };
  const dataTable = () => {
    const columns = [
      {
        name: "Họ và tên",
        selector: (row) => row.student_name,
        sortable: true,
      },
      {
        name: "Ngày sinh",
        selector: (row) => row.student_birthday,
        cell: (row) => dateFormatter(new Date(row.student_birthday * 1000)),
        sortable: true,
      },
    ];

    const data = [];
    if (studentsSearch?.length > 0) {
      studentsSearch
        .filter(
          (student) =>
            listStudentIdsInGroup?.find((id) => id == student.id) == undefined
        )
        .map((item, i) => {
          data.push({
            ...item,
          });

          return item;
        });
    }

    const selectableRowDisabled = (row) => {
      // return listStudentIdsInGroup?.some((item) => item?.id == row.id);
    };

    if (data.length > 0)
      return (
        <DataTables
          data={data}
          columns={columns}
          // selectedRows={selectedRows}
          // selectableRowDisabled={selectableRowDisabled}
          selectableRows
          clearSelectedRows={isTableSelectable}
          onSelectedRowsChange={(item) =>
            onSelectedRowsChange(item.selectedRows)
          }
        />
      );
    else return <p className="text-center">Không có dữ liệu...</p>;
  };
  const checkInput = () => {
    let hasError = false;
    const errorMessage = {
      id_student: "",
      tuition_fee: "",
      reduce_money: "",
      notes: "",
    };

    if (ids?.length === 0) {
      hasError = true;
      errorMessage.id_student = "Không được để trống";
    }

    const tuitionFee = Number(detail.tuition_fee);
    if (
      tuitionFee < 0 ||
      tuitionFee > 999999999 ||
      isNaN(tuitionFee) ||
      !Number.isInteger(tuitionFee)
    ) {
      hasError = true;
      errorMessage.tuition_fee =
        "Vui lòng nhập số nguyên trong khoảng 0 đến 999999999";
    }

    const reduceMoney = Number(detail.reduce_money);
    if (
      reduceMoney < 0 ||
      reduceMoney > 999999999 ||
      isNaN(reduceMoney) ||
      !Number.isInteger(reduceMoney)
    ) {
      hasError = true;
      errorMessage.reduce_money =
        "Vui lòng nhập số nguyên trong khoảng 0 đến 999999999";
    }

    if (
      Number.isInteger(tuitionFee) &&
      Number.isInteger(reduceMoney) &&
      reduceMoney > tuitionFee
    ) {
      hasError = true;
      errorMessage.reduce_money = "Giảm giá không lớn hơn học phí";
    }

    if (hasError) {
      setErrorMessage(errorMessage);
    } else {
      createClassBySubjectDetail();
    }
  };

  return (
    <Modal
      backdrop="static"
      keyboard={false}
      show={show}
      onHide={() => onHide()}
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>Thêm học sinh</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col className="d-flex col-md-12 col-sm-12 mb-2">
            <div
              className="col-6 col-md-5 pe-1"
              style={{ minWidth: "70px", zIndex: 3 }}
            >
              <Select
                options={listClass}
                placeholder="Chọn Lớp"
                value={choiceClass}
                onChange={(choice) => {
                  setChoiceClass(choice);
                  getClassbyId(choice.value);
                }}
              />
            </div>
            <div className="col-3 col-md-7 ps-1">
              <Form.Control
                style={{ height: 39 }}
                type="text"
                placeholder="Nhập tên học sinh"
                value={filterText}
                onChange={(e) => {
                  filterStudent(choiceClass.value, e.target.value, listclassID);
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>{dataTable()}</Col>
          {errorMessage.id_student && (
            <p style={errorTextStyle}>{errorMessage.id_student}</p>
          )}
        </Row>
        <Row>
          <Col>
            <Form.Floating className="mb-3">
              <CurrencyFormat
                className="form-control text-end"
                thousandSeparator={true}
                suffix=" đ"
                displayType="input"
                value={detail.tuition_fee}
                onValueChange={(e) => {
                  setDetail({
                    ...detail,
                    tuition_fee: e.value,
                  });
                }}
                placeholder="Học phí"
                required
              />
              <Form.Label>
                Học phí
                <i className="text-danger">*</i>
              </Form.Label>
              {errorMessage.tuition_fee && (
                <p style={errorTextStyle}>{errorMessage.tuition_fee}</p>
              )}
            </Form.Floating>
          </Col>
          <Col>
            <Form.Floating className="mb-3">
              <CurrencyFormat
                className="form-control text-end"
                thousandSeparator={true}
                displayType="input"
                value={detail.reduce_money}
                onValueChange={(e) => {
                  setDetail({
                    ...detail,
                    reduce_money: e.value,
                  });
                }}
                placeholder="Giảm giá"
                required
              />
              <Form.Label>Giảm giá</Form.Label>

              {errorMessage.reduce_money && (
                <p style={errorTextStyle}>{errorMessage.reduce_money}</p>
              )}
            </Form.Floating>
          </Col>
        </Row>
        {/* Ghi chu */}
        {/* <Form.Floating className="mb-3">
          <Form.Control
            type="text"
            value={detail.notes}
            onChange={(e) => {
              setDetail({
                ...detail,
                notes: e.target.value,
              });
            }}
            as="textarea"
            rows={3}
            style={{ height: "100px" }}
            placeholder="Ghi chú"
            required
          />
          <Form.Label>Ghi chú</Form.Label>

          {errorMessage.notes && (
            <p style={errorTextStyle}>{errorMessage.notes}</p>
          )}
        </Form.Floating> */}
      </Modal.Body>

      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={() => onHide()}>
          <i className="fa-solid fa-times"></i> Đóng
        </Button>

        <Button
          size="sm"
          type="submit"
          variant="success"
          onClick={() => checkInput()}
          disabled={ids?.length < 1}
        >
          <i className="fa-solid fa-check"></i> Lưu
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ClassBySubjectDetailAdd;
