import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import Select from "react-select";
import { Modal, Form, Button, Row, Col, Table } from "react-bootstrap";
import moment from "moment";
import swal from "sweetalert";
import Check from "../../other/Check";
import api from "../../../../helper/axiosInstance";

const dummySchedule = {
  id_day: 0,
  start_at: "",
  end_at: "",
  error: "",
};

const StudyGroupEdit = ({
  show,
  onHide,
  selectedStudyGroupId,
  listTeachers,
  listSubjects,
}) => {
  const [schedules, setSchedules] = useState([dummySchedule]);
  const [schoolDayOptions, setSchoolDayOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [studyGroupDetail, setStudyGroupDetail] = useState({
    code: "",
    name: "",
    id_teacher: "",
    id_subject: "",
    start_date: "",
    end_date: "",
    lesson_time: null,
    lesson_number: null,
    is_status: 1,
  });

  const [errorMessage, setErrorMessage] = useState({
    code: "",
    name: "",
    id_teacher: "",
    id_subject: "",
    start_date: "",
    end_date: "",
    lesson_time: "",
    lesson_number: "",
  });

  useEffect(() => {
    getSubjectDetail();
    getShoolDayOptions();
  }, []);

  const getShoolDayOptions = async () => {
    setIsLoading(true);
    await api
      .get(`/getDayOfWeeks`)
      .then((res) => {
        setIsLoading(false);
        if (res.data.errCode === 0) {
          setSchoolDayOptions(res.data.days);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getSubjectDetail = async () => {
    setIsLoading(true);
    await api
      .get(`/study-group-show?id=${selectedStudyGroupId}`)
      .then((res) => {
        setIsLoading(false);
        const studyGroup = res.data.data.studyGroup;
        setStudyGroupDetail({
          id: studyGroup.id,
          code: studyGroup.code,
          name: studyGroup.name,
          id_teacher: studyGroup.teacher.id,
          id_subject: studyGroup.subject.id,
          lesson_time: studyGroup.lesson_time,
          lesson_number: studyGroup.lesson_number,
          start_date: moment(new Date(studyGroup.start_date * 1000)).format(
            "YYYY-MM-DD"
          ),
          end_date: moment(new Date(studyGroup.end_date * 1000)).format(
            "YYYY-MM-DD"
          ),
          is_status: 1,
        });
        if (studyGroup.timetables) {
          setSchedules([...studyGroup.timetables, dummySchedule]);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast("Đã có lỗi xảy ra vui lòng thử lại", {
          type: "error",
          autoClose: 1000,
        });
      });
  };

  const updateStudyGroup = async () => {
    await swal({
      title: "Bạn muốn cập nhật lịch học này?",
      icon: "warning",
      buttons: ["Đóng", "Cập nhật"],
    }).then(async (ok) => {
      if (ok) {
        setIsLoading(true);

        await api({
          method: "put",
          url: "study-group-update",
          data: {
            ...studyGroupDetail,
            start_date: new Date(studyGroupDetail.start_date) / 1000,
            end_date: new Date(studyGroupDetail.end_date) / 1000,
            lesson_time:
              studyGroupDetail.lesson_time?.length > 0
                ? studyGroupDetail.lesson_time
                : null,
            lesson_number:
              studyGroupDetail.lesson_number?.length > 0
                ? studyGroupDetail.lesson_number
                : null,
            timetables: schedules.filter((schedule) => schedule.id_day !== 0),
          },
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => {
            setIsLoading(false);
            toast(res.data.messages, { type: "success" });
            onHide(true);
            setErrorMessage({
              code: "",
              name: "",
              id_teacher: "",
              id_subject: "",
              start_date: "",
              end_date: "",
              lesson_time: "",
              lesson_number: "",
            });
          })
          .catch((err) => {
            setIsLoading(false);
            toast("Thêm thất bại", {
              type: "error",
              autoClose: 1000,
            });
            setErrorMessage(err.response.data.messages);
          });
      }
    });
  };
  //#endregion API

  //#region Logic
  const checkInput = () => {
    let hasError = false;
    const errorMessage = {
      code: "",
      name: "",
      id_teacher: "",
      id_subject: "",
      start_date: "",
      end_date: "",
      lesson_time: "",
      lesson_number: "",
    };

    if (studyGroupDetail.code.length === 0) {
      hasError = true;
      errorMessage.code = "Không được để trống";
    }
    if (studyGroupDetail.code.length > 100) {
      hasError = true;
      errorMessage.code = "Không quá 100 ký tự";
    }

    if (studyGroupDetail.name.length === 0) {
      hasError = true;
      errorMessage.name = "Không được để trống";
    }
    if (studyGroupDetail.name.length > 100) {
      hasError = true;
      errorMessage.name = "Không quá 100 ký tự";
    }

    if (studyGroupDetail.id_teacher.length === 0) {
      hasError = true;
      errorMessage.id_teacher = "Không được để trống";
    }

    if (studyGroupDetail.id_subject.length === 0) {
      hasError = true;
      errorMessage.id_subject = "Không được để trống";
    }

    if (studyGroupDetail.start_date.length === 0) {
      hasError = true;
      errorMessage.start_date = "Không được để trống";
    }

    if (studyGroupDetail.end_date.length === 0) {
      hasError = true;
      errorMessage.end_date = "Không được để trống";
    }
    if (
      Date.parse(studyGroupDetail.end_date) <
      Date.parse(studyGroupDetail.start_date)
    ) {
      hasError = true;
      errorMessage.end_date = "Không trước ngày bắt đầu";
    }

    const numLessonTime = Number(studyGroupDetail.lesson_time);
    if (
      numLessonTime < 0 ||
      numLessonTime > 10000 ||
      isNaN(numLessonTime) ||
      !Number.isInteger(numLessonTime)
    ) {
      hasError = true;
      errorMessage.lesson_time =
        "Vui lòng nhập số nguyên trong khoảng 0 đến 10000";
    }

    const numLessonNumber = Number(studyGroupDetail.lesson_number);
    if (
      numLessonNumber < 0 ||
      numLessonNumber > 10000 ||
      isNaN(numLessonNumber) ||
      !Number.isInteger(numLessonNumber)
    ) {
      hasError = true;
      errorMessage.lesson_number =
        "Vui lòng nhập số nguyên trong khoảng 0 đến 10000";
    }

    // Lịch học
    const schedulesValidated = schedules.map((schedule, index) => {
      // Không chọn ngày học thì bỏ qua
      if (schedule.id_day === 0) {
        return {
          ...schedule,
          error: "",
        };
      }
      // Nhập thiếu
      if (!(schedule.start_at || schedule.end_at)) {
        hasError = true;
        return {
          ...schedule,
          error: "Giờ bắt đầu, giờ kết thúc không được để trống",
        };
      }

      const startTime = Date.parse(`01/01/2024 ${schedule.start_at}`);
      const endTime = Date.parse(`01/01/2024 ${schedule.end_at}`);

      // Giờ học bị trùng
      let i;
      for (i = 0; i < index; i++) {
        if (schedule.id_day != schedules[i].id_day) {
          continue;
        }
        const endTimeI = Date.parse(`01/01/2024 ${schedules[i].endTime}`);
        if (startTime < endTimeI) {
          return {
            ...schedule,
            error: `Giờ học bị trùng với STT ${i + 1}`,
          };
        }
      }
      // Không có lỗi
      return {
        ...schedule,
        error: "",
      };
    });

    setSchedules(schedulesValidated);
    if (hasError) {
      setErrorMessage(errorMessage);
    } else {
      updateStudyGroup();
    }
  };

  const handleResetErrorMessage = (e) => {
    setErrorMessage({
      ...errorMessage,
      [e.target.name]: "",
    });
  };

  const removeSchedule = (index) => {
    const newSchedules = [...schedules];
    newSchedules.splice(index, 1);
    setSchedules(newSchedules);
  };
  //#endregion Logic

  //#region Render
  const RenderScheduleTable = () => {
    return (
      <div style={{ overflowX: "scroll" }}>
        <Table size="lg" bordered hover>
          <thead className="bg-light">
            <tr className="align-middle text-center">
              <th>STT</th>
              <th>Ngày học</th>
              <th>Giờ bắt đầu</th>
              <th>Giờ kết thúc</th>
              {new Check().permission([146]) ? <th></th> : <></>}
            </tr>
          </thead>
          <tbody>{RenderSchedules()}</tbody>
        </Table>
      </div>
    );
  };

  const RenderSchedules = () => {
    return schedules?.map((scheduleItem, index) => {
      return (
        <>
          <tr key={index} className="align-middle text-center">
            <td>{index + 1}</td>
            <td>
              <Select
                options={schoolDayOptions}
                className="text-start"
                getOptionLabel={(option) => option.day_name}
                getOptionValue={(option) => option.id}
                placeholder={<>Chọn ngày học</>}
                value={
                  schoolDayOptions.find(
                    (shoolDay) => shoolDay.id === scheduleItem.id_day
                  ) || "Chọn ngày học"
                }
                onChange={(choice) => {
                  let newSchedules = schedules.map((item, i) => {
                    if (i === index) {
                      return {
                        ...scheduleItem,
                        id_day: choice.id,
                      };
                    }
                    return item;
                  });

                  if (scheduleItem.id_day === 0) {
                    newSchedules.push(dummySchedule);
                  }

                  setSchedules(newSchedules);
                }}
                menuPortalTarget={document.querySelector("body")}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                }}
              />
            </td>
            <td>
              <Form.Control
                type="time"
                className="text-end"
                value={scheduleItem.start_at}
                onChange={(e) => {
                  let newSchedules = schedules.map((item, i) => {
                    if (i === index) {
                      return {
                        ...scheduleItem,
                        start_at: e.target.value,
                      };
                    }
                    return item;
                  });

                  setSchedules(newSchedules);
                  setErrorMessage({
                    ...errorMessage,
                    [`timetables[${index}].start_at`]: "",
                  });
                }}
                placeholder="Giờ bắt đầu"
                disabled={scheduleItem.id_day === 0}
              />
              <i className="text-danger">
                {errorMessage[`timetables[${index}].start_at`]}
              </i>
            </td>
            <td>
              <Form.Control
                type="time"
                className="text-end"
                value={scheduleItem.end_at}
                onChange={(e) => {
                  let newSchedules = schedules.map((item, i) => {
                    if (i === index) {
                      return {
                        ...scheduleItem,
                        end_at: e.target.value,
                      };
                    }
                    return item;
                  });

                  setSchedules(newSchedules);
                  setErrorMessage({
                    ...errorMessage,
                    [`timetables[${index}].end_at`]: "",
                  });
                }}
                placeholder="Giờ kết thúc"
                disabled={scheduleItem.id_day === 0}
              />
              <i className="text-danger">
                {errorMessage[`timetables[${index}].end_at`]}
              </i>
            </td>

            {new Check().permission([146]) ? (
              <td>
                <Button
                  variant="outline-danger"
                  size="sm"
                  type="button"
                  onClick={() => removeSchedule(index)}
                  disabled={scheduleItem.id_day === 0}
                  title="Xóa"
                >
                  <i className="fa-solid fa-times"></i>
                </Button>
              </td>
            ) : (
              <></>
            )}
          </tr>

          {scheduleItem.error && (
            <tr>
              <td colSpan={5}>
                <i className="text-danger"> {scheduleItem.error}</i>
              </td>
            </tr>
          )}
        </>
      );
    });
  };

  return (
    <Modal
      backdrop="static"
      keyboard={false}
      show={show}
      onHide={() => onHide()}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>Chi tiết nhóm học</Modal.Title>
      </Modal.Header>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <Modal.Body>
          <Row>
            <Col md={4}>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  value={studyGroupDetail.code}
                  name="code"
                  onChange={(e) => {
                    setStudyGroupDetail({
                      ...studyGroupDetail,
                      code: e.target.value,
                    });
                    handleResetErrorMessage(e);
                  }}
                  placeholder="Mã nhóm học tập"
                  required
                />
                <Form.Label>
                  Mã nhóm
                  <i className="text-danger">*</i>
                </Form.Label>

                {errorMessage.code && (
                  <i className="text-danger">{errorMessage.code}</i>
                )}
              </Form.Floating>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  value={studyGroupDetail.name}
                  name="name"
                  onChange={(e) => {
                    setStudyGroupDetail({
                      ...studyGroupDetail,
                      name: e.target.value,
                    });
                    handleResetErrorMessage(e);
                  }}
                  placeholder="Tên nhóm học tập"
                  required
                />
                <Form.Label>
                  Tên nhóm
                  <i className="text-danger">*</i>
                </Form.Label>

                {errorMessage.name && (
                  <i className="text-danger">{errorMessage.name}</i>
                )}
              </Form.Floating>
              {/* Giao vien */}
              <Form.Floating className="mb-3">
                <Form.Select
                  value={studyGroupDetail.id_teacher}
                  name="id_teacher"
                  onChange={(e) => {
                    setStudyGroupDetail({
                      ...studyGroupDetail,
                      id_teacher: e.target.value,
                    });
                    handleResetErrorMessage(e);
                  }}
                  required
                >
                  <option value="">--Chọn--</option>
                  {listTeachers.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.user_name}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Label>
                  Giáo viên<i className="text-danger">*</i>
                </Form.Label>

                {errorMessage.id_teacher && (
                  <i className="text-danger">{errorMessage.id_teacher}</i>
                )}
              </Form.Floating>
              {/* Mon hoc */}
              <Form.Floating className="mb-3">
                <Form.Select
                  value={studyGroupDetail.id_subject}
                  name="id_subject"
                  onChange={(e) => {
                    setStudyGroupDetail({
                      ...studyGroupDetail,
                      id_subject: e.target.value,
                    });

                    handleResetErrorMessage(e);
                  }}
                  required
                >
                  <option value="">--Chọn--</option>
                  {listSubjects.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.subject_name}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Label>
                  Môn học<i className="text-danger">*</i>
                </Form.Label>

                {errorMessage.id_subject && (
                  <i className="text-danger">{errorMessage.id_subject}</i>
                )}
              </Form.Floating>
              {/* Thoi gian */}
              <Row>
                <Col>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="date"
                      className="text-end"
                      value={studyGroupDetail.start_date}
                      name="start_date"
                      onChange={(e) => {
                        setStudyGroupDetail({
                          ...studyGroupDetail,
                          start_date: e.target.value,
                        });
                        handleResetErrorMessage(e);
                      }}
                      placeholder="Ngày bắt đầu"
                      required
                    />
                    <Form.Label>
                      Ngày bắt đầu
                      <i className="text-danger">*</i>
                    </Form.Label>

                    {errorMessage.start_date && (
                      <i className="text-danger">{errorMessage.start_date}</i>
                    )}
                  </Form.Floating>
                </Col>
                <Col>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="date"
                      className="text-end"
                      value={studyGroupDetail.end_date}
                      name="end_date"
                      onChange={(e) => {
                        setStudyGroupDetail({
                          ...studyGroupDetail,
                          end_date: e.target.value,
                        });
                        handleResetErrorMessage(e);
                      }}
                      placeholder="Ngày kết thúc"
                      required
                    />
                    <Form.Label>
                      Ngày kết thúc
                      <i className="text-danger">*</i>
                    </Form.Label>

                    {errorMessage.end_date && (
                      <i className="text-danger">{errorMessage.end_date}</i>
                    )}
                  </Form.Floating>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Floating>
                    <Form.Control
                      type="number"
                      className="text-end"
                      value={studyGroupDetail.lesson_number}
                      onChange={(e) => {
                        setStudyGroupDetail({
                          ...studyGroupDetail,
                          lesson_number: e.target.value,
                        });
                      }}
                      placeholder="Số buổi học"
                    />
                    <Form.Label>Số buổi học</Form.Label>

                    {errorMessage.lesson_number && (
                      <i className="text-danger">
                        {errorMessage.lesson_number}
                      </i>
                    )}
                  </Form.Floating>
                </Col>

                <Col>
                  <Form.Floating>
                    <Form.Control
                      type="number"
                      className="text-end"
                      value={studyGroupDetail.lesson_time}
                      onChange={(e) => {
                        setStudyGroupDetail({
                          ...studyGroupDetail,
                          lesson_time: e.target.value,
                        });
                      }}
                      placeholder="Thời gian / Buổi (phút)"
                    />
                    <Form.Label>Thời gian / Buổi (phút)</Form.Label>

                    {errorMessage.lesson_time && (
                      <i className="text-danger">{errorMessage.lesson_time}</i>
                    )}
                  </Form.Floating>
                </Col>
              </Row>
            </Col>

            <Col md={8}>
              <RenderScheduleTable />
            </Col>
          </Row>
        </Modal.Body>
      )}

      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={() => onHide()}>
          <i className="fa-solid fa-times"></i> Đóng
        </Button>

        {new Check().permission([146]) ? (
          <Button
            size="sm"
            type="submit"
            variant="success"
            onClick={() => checkInput()}
            disabled={isLoading}
          >
            <i className="fa-solid fa-check"></i> Cập nhật
          </Button>
        ) : (
          <></>
        )}
      </Modal.Footer>
    </Modal>
  );
  //#endregion Render
};

export default StudyGroupEdit;
