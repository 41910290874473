import React, { Component } from "react";
import { Card, Table, Button } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import * as XLSX from "xlsx-js-style";
import api from "../../../../helper/axiosInstance";
import { IS_ALL_DAY, IS_STATUS, IS_VALID } from "../../../../constants";
import { findStartDateAndEndDateOfMonth } from "../../../../helper/times";
import { toastError } from "../../../../helper/messages";
import DatePickerCustom from "../../../uiElements/DatePickerCustom";

class FinanceMonthBook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schoolInfo: {},
      vouchers: [],
      // listmealbyclass: [],
      // listStudent: [],
      takeMoney: 0,
      isLoading: false,
      datesInMonth: [],
      currentTime: new Date(),
      groups: [],
      totalStudentsMonth: 0,
      totalMealsMonth: 0,
      totalRevenueMonth: 0,
      totalRefundMonth: 0,
      totalFeeOtherMonth: 0,
      totalIngredientExpensesMonth: 0,
      totalMoneyMonth: 0,
    };
  }

  async componentDidMount() {
    const date = new Date();
    const listDate = this.getDaysInMonth(date.getMonth(), date.getFullYear());
    this.getDetailschool();
    this.setState({
      datesInMonth: listDate,
      currentTime: new Date(),
    });
    this.getDatas();
  }

  getDatas = async (date = new Date()) => {
    this.setState({ isLoading: true });
    date.setHours(0, 0, 0);
    const startOfDay = date.getTime();
    const timestampStartOfDay = Math.floor(startOfDay / 1000);

    date.setHours(23, 59, 59);
    const endOfDay = date.getTime();
    const timestampEndOfDay = Math.floor(endOfDay / 1000);

    const month = findStartDateAndEndDateOfMonth(date);
    const firstUnixTimestamp = month.startDate;

    await this.getReportClass(
      firstUnixTimestamp,
      timestampEndOfDay,
      timestampStartOfDay
    );

    await this.getSettings(
      firstUnixTimestamp,
      timestampEndOfDay,
      timestampStartOfDay
    );

    let vouchers = await this.getVoucherMonth(
      firstUnixTimestamp,
      timestampEndOfDay,
      timestampStartOfDay
    );

    const classesOfMonth = this.state.classesOfMonth;
    const settings = this.state.settings;

    let totalStudentsMonth = 0;
    let totalNotEatAndGoToSchoolMonth = 0;
    let totalStudentOnValidLeaveMonth = 0;
    let totalRevenueMonth = 0;
    let totalFeeOtherMonth = 0;
    let totalRefundMonth = 0;
    let totalIngredientExpensesMonth = 0;
    let totalMoneyMonth = 0;

    vouchers?.map((voucher) => {
      const voucherDate = voucher.vou_date;

      const classOfMonth =
        classesOfMonth?.find(
          (classOfMonth) => classOfMonth.date === voucherDate
        ) || [];

      const classrooms = classOfMonth?.class;

      const settingOfday =
        settings?.find(
          (item) =>
            item.setting_date_start <= voucherDate &&
            item.setting_date_end >= voucherDate
        ) || {};

      const groups = settingOfday?.groups?.map((item) => ({
        ...item,
        countStudent: 0,
        countStudentOff: 0,
        countStudentOnValidLeave: 0,
      }));

      let totalStudents = 0;
      let totalNotEatAndGoToSchool = 0;
      let totalStudentOnValidLeave = 0;
      let totalRevenue = 0;
      let totalFeeOther = 0;
      let totalRefund = 0;
      let totalMoney = 0;

      if (classrooms && voucher) {
        classrooms?.forEach((classroom) => {
          const group = groups?.find(
            (group) => group.id === classroom.id_group
          );

          totalStudents += classroom.countStudent;
          totalNotEatAndGoToSchool += classroom.countNotEatAndGoToSchool;
          totalStudentOnValidLeave += classroom.countStudentOnValidLeave;

          const studentsNotEatAll =
            classroom?.studentsNotEat?.filter(
              (student) => student.is_all_day === IS_ALL_DAY.TRUE
            ) || [];

          const studentsOff =
            classroom?.studentsOff?.filter(
              (student) =>
                student.not_eat_all_day !== IS_ALL_DAY.TRUE &&
                student.is_valid > IS_VALID.FALSE
            ) || [];

          const studentsOffAndNotEatAll =
            classroom?.studentsOff?.filter(
              (student) =>
                student.not_eat_all_day === IS_ALL_DAY.TRUE &&
                student.is_valid >= IS_VALID.TRUE &&
                student.is_status === IS_STATUS.ACTIVE
            ) || [];

          const countStudentNotEat =
            studentsOffAndNotEatAll.length + studentsNotEatAll.length;

          const studentsNotEatTimes =
            classroom?.studentsNotEat?.filter(
              (student) => student.is_all_day === IS_ALL_DAY.FALSE
            ) || [];

          const discountStudentsNotEatTimes =
            studentsNotEatTimes?.reduce(
              (acc, current) => acc + parseInt(current.money_back),
              0
            ) +
            studentsOff?.reduce(
              (acc, current) => acc + parseInt(current.not_eat_money_back),
              0
            );

          const totalStudentMeals =
            classroom.countStudent -
            studentsNotEatAll.length -
            classroom.countStudentOnValidLeave;

          totalRevenue +=
            parseInt(group?.price_meal) *
              (classroom.countStudent - countStudentNotEat) -
            discountStudentsNotEatTimes;

          totalFeeOther += parseInt(group?.fee_other) * totalStudentMeals;

          studentsOff?.map((student) => {
            if (student.not_eat_id_times !== null) {
              totalRefund +=
                parseInt(group?.price_return) - student.not_eat_money_back;
            } else {
              totalRefund += parseInt(group?.price_return);
            }
            return student;
          });

          return classroom;
        });

        totalMoney =
          totalRevenue - voucher.vou_price - totalRefund - totalFeeOther;

        totalStudentsMonth += totalStudents;
        totalNotEatAndGoToSchoolMonth += totalNotEatAndGoToSchool;
        totalStudentOnValidLeaveMonth += totalStudentOnValidLeave;
        totalRevenueMonth += totalRevenue;
        totalFeeOtherMonth += totalFeeOther;
        totalRefundMonth += totalRefund;
        totalIngredientExpensesMonth += voucher.vou_price;
        totalMoneyMonth += totalMoney;
      }

      return voucher;
    });

    const totalMealsMonth =
      totalStudentsMonth -
      totalNotEatAndGoToSchoolMonth -
      totalStudentOnValidLeaveMonth;

    this.setState({
      totalStudentsMonth: totalStudentsMonth,
      totalMealsMonth: totalMealsMonth,
      totalNotEatAndGoToSchoolMonth: totalNotEatAndGoToSchoolMonth,
      totalStudentOnValidLeaveMonth: totalStudentOnValidLeaveMonth,
      totalRevenueMonth: totalRevenueMonth,
      totalFeeOtherMonth: totalFeeOtherMonth,
      totalMoneyMonth: totalMoneyMonth,
      totalIngredientExpensesMonth: totalIngredientExpensesMonth,
      totalRefundMonth: totalRefundMonth,
      isLoading: false,
    });
  };

  getSettings = async (start_date, end_date, selected_date) => {
    await api
      .get(
        `/get-setting-by-date-period?start_date=${start_date}&end_date=${end_date}`
      )
      .then(async (res) => {
        const settings = res.data.data;

        const settingOfday =
          settings?.find(
            (item) =>
              item.setting_date_start <= selected_date &&
              item.setting_date_end >= selected_date
          ) || null;

        const groups = settingOfday.groups.map((item) => ({
          ...item,
          countStudent: 0,
          countStudentOff: 0,
          countStudentOnValidLeave: 0,
        }));

        this.setState({ settings: settings, groups: groups });
      })
      .catch((error) => {});
  };

  getReportClass = async (start_date, end_date) => {
    await api
      .get(`/report-class?start_date=${start_date}&end_date=${end_date}`)
      .then((res) => {
        const classesOfMonth = res.data.data || [];
        this.setState({ classesOfMonth: classesOfMonth });
      })
      .catch((error) => {
        toastError(error);
      });
  };

  getVoucherMonth = async (firstDay, lastDay, dateSelected) => {
    return await api
      .get(`/voucher-show?start_at=${firstDay}&term_at=${lastDay}`)
      .then(async (res) => {
        let vouchers = res.data?.vouchers || [];

        const voucher = vouchers.find(
          (voucher) => voucher.vou_date === dateSelected
        );

        vouchers = vouchers.sort((a, b) => a.vou_date - b.vou_date);

        this.setState({
          vouchers: vouchers,
          voucher: voucher,
        });

        return vouchers;
      })
      .catch((error) => {
        return [];
      });
  };

  //#region Fetch API
  getDetailschool = () => {
    api.get(`/detailSchool`).then((res) => {
      if (res.data.status === true) {
        this.setState({
          schoolInfo: res.data.data[0],
        });
      }
    });
  };

  // countTotalStudentsPresent = (listCut) => {
  //   let total = 0;
  //   listCut.forEach((day) => {
  //     day?.class?.forEach((classItem) => {
  //       const presentStudents =
  //         classItem.countStudent - classItem.countStudentOnValidLeave;
  //       total += presentStudents;
  //     });
  //   });

  //   return total;
  // };
  //#endregion

  //#region Handle Logic
  xport = () => {
    const wb = XLSX.utils.book_new();

    const ws = this.xportFinanceMonthBook();

    XLSX.utils.book_append_sheet(wb, ws, "Bảng công khai chi chợ tháng");

    XLSX.writeFile(wb, "Bảng công khai chi chợ tháng.xlsx");
  };

  xportFinanceMonthBook = () => {
    const table = document.getElementById("finance-month-book");

    var ws = XLSX.utils.table_to_sheet(table, { raw: true });

    const colAlpha = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
    ];

    const borderStyle = {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
      right: { style: "thin" },
    };

    // Style cell
    colAlpha.map((alpha) => {
      ws[`${alpha}1`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "start",
        },
      };

      return alpha;
    });

    colAlpha.map((alpha) => {
      ws[`${alpha}2`].s = {
        font: {
          name: "Times New Roman",
          sz: 16,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
      };

      return alpha;
    });
    colAlpha.map((alpha) => {
      ws[`${alpha}3`].s = {
        font: {
          name: "Times New Roman",
        },
        alignment: {
          vertical: alpha == "I" ? "top" : "center",
          horizontal: alpha == "I" ? "start" : "center",
          wrapText: true,
        },
      };

      return alpha;
    });

    colAlpha.map((alpha) => {
      ws[`${alpha}4`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
        },
        border: borderStyle,
        alignment: {
          vertical: "center",
          horizontal: "center",
          wrapText: true,
        },
      };

      return alpha;
    });

    let ingreBigArray = [];

    this.state.vouchers.map((voucherItem) => {
      ingreBigArray = ingreBigArray.concat(voucherItem.ingredents);
      return voucherItem;
    });
    const aIngredientArray = this.group(ingreBigArray, "id_ingredent");

    const storeArrays =
      aIngredientArray.filter((item) => item[0].ingredent_type === 2) || [];
    const marketArrays =
      aIngredientArray.filter((item) => item[0].ingredent_type === 1) || [];

    let bigArray = [];

    const rows = [
      {},
      { hpt: 50 },
      { hpt: this.findGroundbyDate(this.state.currentTime)?.length * 17 },
      { hpt: 30 },
    ];

    if (storeArrays.length > marketArrays.length) {
      bigArray = storeArrays;
    } else {
      bigArray = marketArrays;
    }

    for (let i = 0; i < bigArray.length + 6; i++) {
      colAlpha.map((alpha) => {
        if (ws[`${alpha}${5 + i}`].v === "empty") {
          ws[`${alpha}${5 + i}`].v = " ";
        }

        if (alpha === "A" || alpha === "G") {
          ws[`${alpha}${5 + i}`].s = {
            font: {
              name: "Times New Roman",
            },
            border: borderStyle,
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
          };
        }

        if (alpha === "B" || alpha === "H" || alpha === "C" || alpha === "I") {
          ws[`${alpha}${5 + i}`].s = {
            font: {
              name: "Times New Roman",
            },
            border: borderStyle,
            alignment: {
              vertical: "center",
              horizontal: "left",
            },
          };
        }

        if (
          alpha === "D" ||
          alpha === "E" ||
          alpha === "F" ||
          alpha === "J" ||
          alpha === "K" ||
          alpha === "L"
        ) {
          ws[`${alpha}${5 + i}`].s = {
            font: {
              name: "Times New Roman",
            },
            border: borderStyle,
            alignment: {
              vertical: "center",
              horizontal: "right",
            },
          };
        }

        return alpha;
      });

      rows.push({});
    }

    // Near last row
    colAlpha.map((alpha) => {
      if (alpha === "A" || alpha === "G") {
        ws[`${alpha}${5 + bigArray.length}`].s = {
          font: {
            name: "Times New Roman",
            bold: true,
          },
          border: borderStyle,
          alignment: {
            vertical: "center",
            horizontal: "center",
          },
        };
      } else if (alpha === "F" || alpha === "L") {
        ws[`${alpha}${5 + bigArray.length}`].s = {
          font: {
            name: "Times New Roman",
            bold: true,
          },
          border: borderStyle,
          alignment: {
            vertical: "center",
            horizontal: "right",
          },
        };
      } else {
        ws[`${alpha}${5 + bigArray.length}`].s = {
          font: {
            name: "Times New Roman",
          },
          border: borderStyle,
          alignment: {
            vertical: "center",
            horizontal: "center",
          },
        };
      }

      return alpha;
    });

    rows.push({});

    // Last row
    for (let i = 0; i <= 4; i++) {
      colAlpha.map((alpha) => {
        if (alpha === "A" || alpha === "D" || alpha === "G" || alpha === "J") {
          ws[`${alpha}${5 + bigArray.length + 1 + i}`].s = {
            font: {
              name: "Times New Roman",
              bold: true,
            },
            border: borderStyle,
            alignment: {
              wrapText: true,
            },
          };
        } else if (
          alpha === "C" ||
          alpha === "F" ||
          alpha === "I" ||
          alpha === "L"
        ) {
          ws[`${alpha}${5 + bigArray.length + 1 + i}`].s = {
            font: {
              name: "Times New Roman",
              bold: true,
            },
            border: borderStyle,
            alignment: {
              wrapText: true,
              vertical: "center",
              horizontal: "right",
            },
          };
        } else {
          ws[`${alpha}${5 + bigArray.length + 1 + i}`].s = {
            font: {
              name: "Times New Roman",
            },
            border: borderStyle,
            alignment: {
              wrapText: true,
              vertical: "center",
              horizontal: "center",
            },
          };
        }

        return alpha;
      });
    }

    rows.push({ hpt: 30 });

    ws["!cols"] = [
      { wch: 4 },
      { wch: 14.5 },
      { wch: 10.5 },
      { wch: 9 },
      { wch: 9 },
      { wch: 10.5 },

      { wch: 4 },
      { wch: 14.5 },
      { wch: 10.5 },
      { wch: 9 },
      { wch: 9 },
      { wch: 10.5 },
    ];

    ws["!rows"] = rows;

    const merge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 11 } },
      { s: { r: 1, c: 0 }, e: { r: 1, c: 11 } },

      { s: { r: 2, c: 0 }, e: { r: 2, c: 3 } },
      { s: { r: 2, c: 4 }, e: { r: 2, c: 7 } },
      { s: { r: 2, c: 8 }, e: { r: 2, c: 11 } },

      {
        s: { r: 5 + bigArray.length - 1, c: 0 },
        e: { r: 5 + bigArray.length - 1, c: 4 },
      },
      {
        s: { r: 5 + bigArray.length - 1, c: 6 },
        e: { r: 5 + bigArray.length - 1, c: 10 },
      },
    ];
    for (let i = 0; i <= 4; i++) {
      merge.push({
        s: { r: 5 + bigArray.length + i, c: 0 },
        e: { r: 5 + bigArray.length + i, c: 5 },
      });
      merge.push({
        s: { r: 5 + bigArray.length + i, c: 6 },
        e: { r: 5 + bigArray.length + i, c: 10 },
      });
    }
    ws["!merges"] = merge;

    return ws;
  };

  getDaysInMonth = (month, year) => {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  group = (arr, key) => {
    return [
      ...arr
        .reduce(
          (acc, o) => acc.set(o[key], (acc.get(o[key]) || []).concat(o)),
          new Map()
        )
        .values(),
    ];
  };
  //#endregion
  calculateTotalArray = (A) => {
    const result = [];
    A?.forEach((item) => {
      const { date } = item;
      const dayResult = { date, groups: [] };

      const groupMap = {};

      item?.class?.forEach((classItem) => {
        const { id_group, group_name, countStudent, countStudentOnValidLeave } =
          classItem;

        if (!groupMap[id_group]) {
          groupMap[id_group] = {
            id_group,
            group_name,
            countStudentTotal: 0,
            countStudentOnValidLeaveTolal: 0,
          };
        }
        groupMap[id_group].countStudentTotal += countStudent;
        groupMap[id_group].countStudentOnValidLeaveTolal +=
          countStudentOnValidLeave;
      });
      dayResult.groups = Object.values(groupMap);
      result?.push(dayResult);
    });

    return result;
  };
  findGroundbyDate = (date) => {
    let day = date / 1000;
    return (
      this.state.settings?.filter(
        (setting) =>
          setting.setting_date_start <= day && setting.setting_date_end >= day
      )[0]?.groups || []
    );
  };

  calculateTotalPriceMeal = (mealGroup) => {
    let totalPrice = 0;
    mealGroup.forEach((meal) => {
      const matchingGroups = this.findGroundbyDate(new Date(meal.date * 1000));

      if (matchingGroups) {
        meal?.groups?.forEach((mealGroupItem) => {
          const matchingGroup = matchingGroups?.find(
            (group) => group.id == mealGroupItem.id_group
          );
          if (matchingGroup) {
            totalPrice +=
              mealGroupItem.countStudentTotal *
              (parseFloat(matchingGroup.fee_meal) +
                parseFloat(matchingGroup.fee_other));
          }
        });
      }
    });

    return totalPrice;
  };
  calculateTotalFeeOther = (mealGroup) => {
    let totalPrice = 0;
    mealGroup.forEach((meal) => {
      const matchingGroups = this.findGroundbyDate(new Date(meal.date * 1000));

      if (matchingGroups) {
        meal?.groups?.forEach((mealGroupItem) => {
          const matchingGroup = matchingGroups?.find(
            (group) => group.id == mealGroupItem.id_group
          );
          if (matchingGroup) {
            totalPrice +=
              (mealGroupItem.countStudentTotal -
                mealGroupItem.countStudentOnValidLeaveTolal) *
              parseFloat(matchingGroup.fee_other);
          }
        });
      }
    });

    return totalPrice;
  };
  calculateTotalReturn = (mealGroup) => {
    let totalPrice = 0;
    mealGroup.forEach((meal) => {
      const matchingGroups = this.findGroundbyDate(new Date(meal.date * 1000));
      if (matchingGroups) {
        meal?.groups?.forEach((mealGroupItem) => {
          const matchingGroup = matchingGroups?.find(
            (group) => group.id == mealGroupItem.id_group
          );
          if (matchingGroup) {
            totalPrice +=
              mealGroupItem.countStudentOnValidLeaveTolal *
              parseInt(matchingGroup.price_return, 10);
          }
        });
      }
    });

    return totalPrice;
  };
  // Render
  RenderFinanceTable() {
    // let totalTakeMoney = 0;
    // let totalReturnMoney = 0;
    // let totalMeal = 0;

    this.state.datesInMonth.map((date) => {
      const start = date.setHours(0, 0, 0, 0) / 1000;
      const end = date.setHours(23, 59, 59, 0) / 1000;

      if (
        !this.state.vouchers.find(
          (voucher) => Number(voucher.vou_date) === start
        )
      ) {
        return date;
      }

      // const mealInDate = [];

      // this.state.listmealbyclass.map((mealcut) => {
      //   if (start <= mealcut.meal_day && mealcut.meal_day <= end) {
      //     mealInDate.push(mealcut);
      //   }
      //   return mealcut;
      // });

      // let returnMoney = 0;

      // mealInDate.map((meal) => {
      //   if (Number(meal.is_term) === 1) {
      //     if (Number(meal.type_meal) === 1) {
      //       returnMoney += Number(meal.price_return);
      //     } else {
      //       meal.detail.map((detailItem) => {
      //         returnMoney += Number(detailItem.price_return);

      //         return detailItem;
      //       });
      //     }
      //   }
      //   return meal;
      // });

      // let totalPerson = this.state.listStudent.length;
      // let pauseStudent = 0;

      // this.state.listStudent.map((student) => {
      //   let isStillStudy = false;

      //   student.process.map((period) => {
      //     if (period.end_at !== null) {
      //       if (
      //         period.start_at <= date.getTime() / 1000 &&
      //         date.getTime() / 1000 <= period.end_at
      //       ) {
      //         isStillStudy = true;
      //       }
      //     } else {
      //       if (period.start_at <= date.getTime() / 1000) {
      //         isStillStudy = true;
      //       }
      //     }
      //     return period;
      //   });

      //   if (!isStillStudy) {
      //     pauseStudent++;
      //   }

      //   return student;
      // });

      // totalPerson -= pauseStudent;
      // let takeMoneyInDay = this.state.takeMoney * totalPerson;
      // totalPerson -= mealInDate.filter(
      //   (mealCut) => Number(mealCut.type_meal) === 1 && mealCut.is_term === 1
      // ).length;
      // totalReturnMoney += returnMoney;
      // totalTakeMoney += takeMoneyInDay;
      // totalMeal += totalPerson;
      return date;
    });

    let ingreBigArray = [];

    this.state.vouchers?.map((voucherItem) => {
      ingreBigArray = ingreBigArray.concat(voucherItem.ingredents);

      return voucherItem;
    });
    const aIngredientArray = this.group(ingreBigArray, "id_ingredent");
    const storeArrays =
      aIngredientArray.filter((item) => item[0].ingredent_type === 2) || [];
    const marketArrays =
      aIngredientArray.filter((item) => item[0].ingredent_type === 1) || [];

    let bigArray = [];

    if (storeArrays.length > marketArrays.length) {
      bigArray = storeArrays;
    } else {
      bigArray = marketArrays;
    }

    let totalStoreMoney = 0;
    let totalMarketMoney = 0;

    return (
      <div style={{ overflowX: "scroll" }}>
        <Table bordered style={{ minWidth: 999 }}>
          <thead>
            <tr className="align-middle">
              <th colSpan={12}>{this.state.schoolInfo.school_name}</th>
            </tr>
            <tr className="text-center align-middle">
              <th colSpan={12}>BẢNG CÔNG KHAI CHI CHỢ</th>
            </tr>
            <tr className="align-middle">
              <th colSpan={4} className="text-center ">
                Tháng {this.state.currentTime.getMonth() + 1} Năm{" "}
                {this.state.currentTime.getFullYear()}{" "}
              </th>
              <th colSpan={4} className="text-center ">
                Tổng số suất ăn:&nbsp;
                <CurrencyFormat
                  value={this.state.totalMealsMonth}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
              <th colSpan={4} className="text-end ">
                Định mức chi:&nbsp;
                {this.findGroundbyDate(this.state.currentTime)?.map(
                  (item, index) => {
                    return (
                      <span key={`spendingNorms${index}`}>
                        <span>
                          {item.group_name}:{" "}
                          <CurrencyFormat
                            value={
                              parseFloat(item.fee_meal) +
                              parseFloat(item.fee_other)
                            }
                            thousandSeparator={true}
                            allowNegative={false}
                            isNumericString
                            displayType="text"
                          />{" "}
                          / Bé
                        </span>
                        <br />
                      </span>
                    );
                  }
                )}
              </th>
            </tr>
            <tr className="text-center">
              <th className="align-middle">STT</th>
              <th className="align-middle">Thực phẩm kho</th>
              <th className="align-middle">ĐVT</th>
              <th className="align-middle">Số lượng</th>
              <th className="align-middle">Đơn giá</th>
              <th className="align-middle">Thành tiền</th>
              <th className="align-middle">STT</th>
              <th className="align-middle">Thực phẩm chợ</th>
              <th className="align-middle">ĐVT</th>
              <th className="align-middle">Số lượng</th>
              <th className="align-middle">Đơn giá</th>
              <th className="align-middle">Thành tiền</th>
            </tr>
          </thead>
          <tbody>
            {bigArray.length !== 0 ? (
              bigArray.map((_, index) => {
                const storeArray = storeArrays[index];
                const marketArray = marketArrays[index];

                let marketMoney = 0;
                let marketAmount = 0;
                let marketAvgPrice = 0;

                let storeMoney = 0;
                let storeAmount = 0;
                let storeAvgPrice = 0;

                if (!storeArray) {
                  marketArray.map((marketItem) => {
                    marketMoney +=
                      parseFloat(marketItem.ing_price) *
                      parseFloat(marketItem.ing_quantity);
                    marketAvgPrice += parseFloat(marketItem.ing_price);
                    marketAmount += parseFloat(marketItem.ing_quantity);
                    return marketItem;
                  });

                  marketAmount = Number(marketAmount).toFixed(4);
                  marketMoney = Number(marketMoney.toFixed(0));

                  const avgPriceIngredient =
                    (marketMoney / marketAmount).toFixed(0) % Infinity || 0;

                  totalMarketMoney += marketMoney;

                  return (
                    <tr className="align-middle" key={index}>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="text-center">{index + 1}</td>
                      <td>{marketArray[0].ingredent_name}</td>
                      <td>{marketArray[0].unit_market_name}</td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={parseFloat(marketAmount)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                          decimalScale={4}
                        />
                      </td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={parseInt(avgPriceIngredient)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                          decimalScale={0}
                        />
                      </td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={parseInt(marketMoney)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                          decimalScale={0}
                        />
                      </td>
                    </tr>
                  );
                }

                if (!marketArray) {
                  storeArray.map((storeItem) => {
                    storeMoney +=
                      parseFloat(storeItem.ing_price) *
                      parseFloat(storeItem.ing_quantity);
                    storeAvgPrice += parseFloat(storeItem.ing_price);
                    storeAmount += parseFloat(storeItem.ing_quantity);
                    return storeItem;
                  });

                  totalStoreMoney += storeMoney;

                  return (
                    <tr className="align-middle" key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td>{storeArray[0].ingredent_name}</td>
                      <td>{storeArray[0].unit_market_name}</td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={Number(storeAmount).toFixed(4)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                      </td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={
                            Number(
                              storeMoney.toFixed(0) / storeAmount.toFixed(4)
                            ).toFixed(0) % Infinity || 0
                          }
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                      </td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={Number(storeMoney).toFixed(0)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  );
                }

                storeArray.map((storeItem) => {
                  storeMoney +=
                    parseFloat(storeItem.ing_price) *
                    parseFloat(storeItem.ing_quantity);
                  storeAvgPrice += parseFloat(storeItem.ing_price);
                  storeAmount += parseFloat(storeItem.ing_quantity);
                  return storeItem;
                });

                marketArray.map((marketItem) => {
                  marketMoney +=
                    parseFloat(marketItem.ing_price) *
                    parseFloat(marketItem.ing_quantity);
                  marketAvgPrice += parseFloat(marketItem.ing_price);
                  marketAmount += parseFloat(marketItem.ing_quantity);
                  return marketItem;
                });

                totalStoreMoney += storeMoney;
                totalMarketMoney += marketMoney;

                storeAmount = Number(storeAmount).toFixed(4);
                storeMoney = Number(storeMoney).toFixed(0);
                const storeAvgIngredientPrice =
                  (storeMoney / storeAmount).toFixed(0) % Infinity || 0;

                marketAmount = Number(marketAmount).toFixed(4);
                marketMoney = Number(marketMoney).toFixed(0);
                const marketAvgIngredientPrice =
                  (marketMoney / marketAmount).toFixed(0) % Infinity || 0;

                return (
                  <tr className="align-middle" key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td>{storeArray[0].ingredent_name}</td>
                    <td>{storeArray[0].unit_market_name} </td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseFloat(storeAmount)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                        decimalScale={4}
                      />
                    </td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseInt(storeAvgIngredientPrice)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                        decimalScale={0}
                      />
                    </td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseFloat(storeMoney)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                        decimalScale={0}
                      />
                    </td>
                    <td className="text-center">{index + 1}</td>
                    <td>{marketArray[0].ingredent_name}</td>
                    <td>{marketArray[0].unit_market_name}</td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseFloat(marketAmount)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                        decimalScale={4}
                      />
                    </td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseInt(marketAvgIngredientPrice)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                        decimalScale={0}
                      />
                    </td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseInt(marketMoney)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                        decimalScale={0}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="text-center align-middle" colSpan={12}>
                  Không có dữ liệu
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <th className="text-end align-middle" colSpan={5}>
                Cộng
              </th>
              <th className="text-end align-middle">
                <CurrencyFormat
                  value={totalStoreMoney.toFixed(0)}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
              <th className="text-end align-middle" colSpan={5}>
                Cộng
              </th>
              <th className="text-end align-middle">
                <CurrencyFormat
                  value={totalMarketMoney.toFixed(0)}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
            <tr>
              <th colSpan={6}></th>
              <th className="text-start align-middle" colSpan={5}>
                Tổng thu theo danh sách
              </th>
              <th className="text-end align-middle">
                <CurrencyFormat
                  value={this.state.totalRevenueMonth}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
            <tr>
              <th colSpan={6}></th>
              <th className=" align-middle" colSpan={5}>
                Đã chi thực phẩm
              </th>
              <th className="text-end align-middle">
                <CurrencyFormat
                  value={this.state.totalIngredientExpensesMonth}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
            <tr>
              <th colSpan={6}></th>
              <th className=" align-middle" colSpan={5}>
                Tổng chi phí khác
              </th>
              <th className="text-end align-middle">
                <CurrencyFormat
                  value={this.state.totalFeeOtherMonth}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
            <tr>
              <th colSpan={6}></th>
              <th colSpan={5} className="align-middle">
                Tổng tiền trả lại
              </th>
              <th className="text-end align-middle">
                <CurrencyFormat
                  value={this.state.totalRefundMonth}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
            <tr>
              <th colSpan={6}></th>
              <th className=" align-middle" colSpan={5}>
                Thừa (thiếu) trong tháng
              </th>
              <th className="text-end align-middle">
                <CurrencyFormat
                  value={this.state.totalMoneyMonth}
                  thousandSeparator={true}
                  allowNegative={true}
                  displayType="text"
                />
              </th>
            </tr>
          </tfoot>
        </Table>

        <Table bordered id="finance-month-book" className="d-none">
          <thead>
            <tr>
              <th>{this.state.schoolInfo.school_name}</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
            </tr>
            <tr>
              <th>BẢNG CÔNG KHAI CHI CHỢ</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
            </tr>
            <tr>
              <th>
                Tháng {this.state.currentTime.getMonth() + 1} Năm{" "}
                {this.state.currentTime.getFullYear()}{" "}
              </th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>
                Tổng số suất ăn:&nbsp;
                <CurrencyFormat
                  value={this.state.totalMealsMonth}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>
                Định mức chi:&nbsp;
                {this.findGroundbyDate(this.state.currentTime)?.map(
                  (item, index) => {
                    return (
                      <span key={`spendingNorms${index}`}>
                        <span>
                          {item.group_name}:{" "}
                          <CurrencyFormat
                            value={
                              parseFloat(item.fee_meal) +
                              parseFloat(item.fee_other)
                            }
                            thousandSeparator={true}
                            allowNegative={false}
                            isNumericString
                            displayType="text"
                          />{" "}
                          / Bé
                        </span>
                        <br />
                      </span>
                    );
                  }
                )}
              </th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
            </tr>
            <tr>
              <th>STT</th>
              <th>Thực phẩm kho</th>
              <th>ĐVT</th>
              <th>Số lượng</th>
              <th>Đơn giá</th>
              <th>Thành tiền</th>
              <th>STT</th>
              <th>Thực phẩm chợ</th>
              <th>ĐVT</th>
              <th>Số lượng</th>
              <th>Đơn giá</th>
              <th>Thành tiền</th>
            </tr>
          </thead>
          <tbody>
            {bigArray.length !== 0 ? (
              bigArray.map((_, index) => {
                const storeArray = storeArrays[index];
                const marketArray = marketArrays[index];

                let marketMoney = 0;
                let marketAmount = 0;
                let marketAvgPrice = 0;
                let storeMoney = 0;
                let storeAmount = 0;
                let storeAvgPrice = 0;

                if (!storeArray) {
                  marketArray.map((marketItem) => {
                    marketMoney +=
                      parseFloat(marketItem.ing_price) *
                      parseFloat(marketItem.ing_quantity);
                    marketAvgPrice += parseFloat(marketItem.ing_price);
                    marketAmount += parseFloat(marketItem.ing_quantity);

                    return marketItem;
                  });

                  marketAmount = Number(marketAmount).toFixed(4);
                  marketMoney = Number(marketMoney).toFixed(0);
                  const marketAvgPriceIngredient =
                    Number(marketMoney / marketAmount).toFixed(0) % Infinity ||
                    0;

                  return (
                    <tr className="align-middle" key={index}>
                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>
                      <td className="text-center">{index + 1}</td>
                      <td>{marketArray[0].ingredent_name}</td>
                      <td>{marketArray[0].unit_market_name}</td>
                      <td>
                        <CurrencyFormat
                          value={parseFloat(marketAmount)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                          decimalScale={4}
                        />
                      </td>
                      <td>
                        <CurrencyFormat
                          value={parseInt(marketAvgPriceIngredient)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                          decimalScale={0}
                        />
                      </td>
                      <td>
                        <CurrencyFormat
                          value={parseInt(marketMoney)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                          decimalScale={0}
                        />
                      </td>
                    </tr>
                  );
                }

                if (!marketArray) {
                  storeArray.map((storeItem) => {
                    storeMoney +=
                      parseFloat(storeItem.ing_price) *
                      parseFloat(storeItem.ing_quantity);
                    storeAvgPrice += parseFloat(storeItem.ing_price);
                    storeAmount += parseFloat(storeItem.ing_quantity);
                    return storeItem;
                  });

                  storeAmount = Number(storeAmount).toFixed(4);
                  storeMoney = Number(storeMoney).toFixed(0);
                  const storeAvgIngredientPrice =
                    (storeMoney / storeAmount).toFixed(0) % Infinity || 0;

                  return (
                    <tr className="align-middle" key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td>{storeArray[0].ingredent_name}</td>
                      <td>{storeArray[0].unit_market_name}</td>
                      <td>
                        <CurrencyFormat
                          value={parseInt(storeAmount)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                      </td>
                      <td>
                        <CurrencyFormat
                          value={parseInt(storeAvgIngredientPrice)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                      </td>
                      <td>
                        <CurrencyFormat
                          value={parseInt(storeMoney)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                      </td>
                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>
                    </tr>
                  );
                }
                storeArray.map((storeItem) => {
                  storeMoney +=
                    parseFloat(storeItem.ing_price) *
                    parseFloat(storeItem.ing_quantity);
                  storeAvgPrice += parseFloat(storeItem.ing_price);
                  storeAmount += parseFloat(storeItem.ing_quantity);
                  return storeItem;
                });

                marketArray.map((marketItem) => {
                  marketMoney +=
                    parseFloat(marketItem.ing_price) *
                    parseFloat(marketItem.ing_quantity);
                  marketAvgPrice += parseFloat(marketItem.ing_price);
                  marketAmount += parseFloat(marketItem.ing_quantity);
                  return marketItem;
                });

                storeAmount = Number(storeAmount).toFixed(4);
                storeMoney = Number(storeMoney).toFixed(0);
                const storeAvgIngredientPrice =
                  (storeMoney / storeAmount).toFixed(0) % Infinity || 0;

                marketAmount = Number(marketAmount).toFixed(4);
                marketMoney = Number(marketMoney).toFixed(0);
                const marketAvgIngredientPrice =
                  (marketMoney / marketAmount).toFixed(0) % Infinity || 0;

                return (
                  <tr className="align-middle" key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td>{storeArray[0].ingredent_name}</td>
                    <td>{storeArray[0].unit_market_name}</td>
                    <td>
                      <CurrencyFormat
                        value={parseFloat(storeAmount)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                        decimalScale={4}
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        value={parseInt(storeAvgIngredientPrice)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                        decimalScale={0}
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        value={parseInt(storeMoney)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                        decimalScale={0}
                      />
                    </td>
                    <td>{index + 1}</td>
                    <td>{marketArray[0].ingredent_name}</td>
                    <td>{marketArray[0].unit_market_name}</td>
                    <td>
                      <CurrencyFormat
                        value={parseFloat(marketAmount)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                        decimalScale={4}
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        value={parseInt(marketAvgIngredientPrice)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                        decimalScale={0}
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        value={parseInt(marketMoney)}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                        decimalScale={0}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={12}>Không có dữ liệu</td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <th>Cộng</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>
                <CurrencyFormat
                  value={totalStoreMoney.toFixed(0)}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
              <th>Cộng</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>
                <CurrencyFormat
                  value={totalMarketMoney.toFixed(0)}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
            <tr>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>Tổng thu theo danh sách</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>
                <CurrencyFormat
                  value={this.state.totalRevenueMonth}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
            <tr>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>Đã chi thực phẩm</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>
                <CurrencyFormat
                  value={this.state.totalIngredientExpensesMonth}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
            <tr>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>Tổng chi phí khác</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th className="text-end align-middle">
                <CurrencyFormat
                  value={this.state.totalFeeOtherMonth}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
            <tr>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>Tổng tiền trả lại</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>
                <CurrencyFormat
                  value={this.state.totalRefundMonth}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
            <tr>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>Thừa (thiếu) trong tháng</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>
                <CurrencyFormat
                  value={this.state.totalMoneyMonth}
                  thousandSeparator={true}
                  allowNegative={true}
                  displayType="text"
                />
              </th>
            </tr>
          </tfoot>
        </Table>
      </div>
    );
  }

  render() {
    return (
      <Card>
        <Card.Header>
          <div className="d-block d-md-flex justify-content-between">
            <div className="d-block d-sm-flex mb-3 mb-sm-0">
              <Card.Title className="me-3 me-3 pt-1">
                <i className="fas fa-list me-1"></i> Sổ tài chính công khai
                Tháng {this.state.currentTime.getMonth() + 1} Năm{" "}
                {this.state.currentTime.getFullYear()}
              </Card.Title>
              <div className="d-flex align-items-center">
                <DatePickerCustom
                  selected={this.state.currentTime}
                  onChange={(date) => {
                    const listDate = this.getDaysInMonth(
                      date.getMonth(),
                      date.getFullYear()
                    );
                    this.setState({
                      datesInMonth: listDate,
                      currentTime: date,
                    });
                    this.getDatas(listDate[listDate.length - 1]);
                  }}
                  showMonthYearPicker
                />
              </div>
            </div>

            <Button
              variant="success"
              size="sm"
              onClick={() => {
                this.xport();
              }}
              disabled={this.state.vouchers?.length === 0}
            >
              Xuất file <i className="fa-solid fa-file-export"></i>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          {this.state.isLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <div
                className="spinner-border text-primary"
                role="status"
                style={{ width: "3rem", height: "3rem" }}
              >
                <span className="sr-only">Đang tải báo cáo thu chi...</span>
              </div>
            </div>
          ) : (
            this.RenderFinanceTable()
          )}
        </Card.Body>
      </Card>
    );
  }
}

export default FinanceMonthBook;
