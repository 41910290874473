import React, { Component } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { Button, Row, Col, Card, Container, Breadcrumb } from "react-bootstrap";
import DataTables from "../../admin/datatable/DataTables";
import YearSchoolFormModal from "./YearSchoolFormModal";
import Check from "../../admin/other/Check";
import HolidayModal from "./HolidayModal";
import api from "../../../helper/axiosInstance";

class YearSchool extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      modalRoleShow: false,
      alertShow: false,
      role: {
        id: "",
        role_name: "",
        is_rank: "",
        permission: [],
      },
      selectedRows: [],
      checkload: true,
      years: [],
      showModalHoliday: false,
      id_add: 0,
    };
  }

  componentDidMount() {
    document.title = "Năm học - Nghỉ lễ";
    this.getRoles();
  }

  getRoles = () => {
    api
      .get(`/school-year-shows`)
      .then((res) => {
        if (res.data.data?.status === true) {
          this.setState({ checkload: false });
          this.setState({ years: res.data.data?.schoolYears });
        }
      })
      .catch((err) => {});
  };

  getDayOffbyYear = (id) => {
    api
      .get(`/viewHolidaysBySchoolYear?id_school_year=${id}`)
      .then((res) => {
        if (res.data.status === true) {
          return res.data.data;
        } else return [];
      })
      .catch((err) => {});
  };

  getDate = (data) => {
    let date = new Date(data * 1000);
    let result =
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear();
    return result;
  };

  getRole = (id) => {
    if (id !== "") {
      api
        .get(`/viewSchoolYear?id=${id}`)
        .then((res) => {
          if (res.data.status === true) {
            const data = res.data.data[0];
            this.setState({
              role: {
                id: data.id,
                role_name: data.year_name,
                start_date: data.start_date,
                end_date: data.end_date,
              },
            });

            this.setState({ modalRoleShow: true });
          }
        })
        .catch((err) => {});
    }
  };

  handleDelete = (item) => {
    swal({
      title: "Bạn muốn xóa năm học?",
      text: `"${item.year_name}"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    })
      .then((ok) => {
        if (ok) {
          api
            .delete(`/school-year-destroy?id=${item.id}`)
            .then((res) => {
              toast(`${res.data.messages}`, {
                type: "success",
                autoClose: 1000,
              });
              this.getRoles();
            })
            .catch((err) => {
              toast(err.response.data.messages.id || err.message, {
                type: "error",
                autoClose: 1000,
              });
            });
        }
      })
      .catch((err) => {});
  };

  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };

  EditHoliday = (item) => {
    this.setState({ showModalHoliday: true });
    this.setState({
      role: item,
      id: item.id,
    });
  };

  deleteHoliday = (item) => {
    swal({
      title: "Bạn muốn xóa ngày nghỉ?",
      text: `"${item.holiday_name}"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    })
      .then((ok) => {
        if (ok) {
          api
            .delete(`/holiday-destroy?id=${item.id}`)
            .then((res) => {
              toast(`${res.data.messages}`, {
                type: "success",
                autoClose: 1000,
              });
              this.getRoles();
            })
            .catch((err) => {
              toast(`Đã phát sinh lỗi!`, { type: "error", autoClose: 1000 });
            });
        }
      })
      .catch((err) => {});
  };

  dataTable() {
    const ExpandedComponent = ({ listday }) => {
      const columns = [
        {
          name: "STT",
          selector: (row, index) => index + 1,
          center: true,
        },
        {
          name: "Tên ngày nghỉ",
          selector: (row) => row.holiday_name,
          sortable: true,
        },
        {
          name: "Ngày bắt đầu",
          selector: (row) => row.start_date,
          right: true,
          sortable: true,
          cell: (row) => this.getDate(row.start_date),
        },
        {
          name: "Ngày kết thúc",
          selector: (row) => row.end_date,
          right: true,
          sortable: true,
          cell: (row) => this.getDate(row.end_date),
        },
        {
          name: "Phương thức",
          center: true,
          cell: (row) => (
            <>
              {new Check().permission(["102", "100"], "or") ? (
                <Button
                  size="sm"
                  variant="warning me-2"
                  type="button"
                  onClick={() => this.EditHoliday(row)}
                  title="Chi tiết ngày nghỉ"
                >
                  {new Check().permission(["102"]) ? (
                    <i className="fa-solid fa-pencil"></i>
                  ) : (
                    <i className="fa-solid fa-eye"></i>
                  )}
                </Button>
              ) : (
                <></>
              )}

              {new Check().permission(["103"]) ? (
                <Button
                  size="sm"
                  variant="danger"
                  type="button"
                  onClick={() => this.deleteHoliday(row)}
                  title="Xóa ngày nghỉ"
                >
                  <i className="fas fa-trash"></i>
                </Button>
              ) : (
                <></>
              )}
            </>
          ),
        },
      ];

      return data.length > 0 ? (
        <DataTables className="ms-5" data={listday} columns={columns} />
      ) : (
        <i className="text-muted ms-5">Không có ngày nghỉ !</i>
      );
    };

    const columns = [
      {
        name: "STT",
        selector: (row, index) => index + 1,
        center: true,
      },
      {
        name: "Tên năm học",
        selector: (row) => row.year_name,
        sortable: true,
      },
      {
        name: "Ngày bắt đầu",
        selector: (row) => row.start_date,
        right: true,
        sortable: true,
        cell: (row) => this.getDate(row.start_date),
      },
      {
        name: "Ngày kết thúc",
        selector: (row) => row.end_date,
        right: true,
        sortable: true,
        cell: (row) => this.getDate(row.end_date),
      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };

    if (this.state.years.length > 0) {
      this.state.years.map((item, i) => {
        data.push({
          id: item.id,
          start_date: item.start_date,
          end_date: item.end_date,
          year_name: item.year_name,
          listday: item.holidays,
          setting: (
            <>
              {new Check().permission(["101"]) ? (
                <Button
                  size="sm"
                  variant="success me-2"
                  type="button"
                  onClick={() =>
                    this.setState({ showModalHoliday: true, id_add: item.id })
                  }
                  title="Thêm ngày nghỉ"
                >
                  <i className="fa-solid fa-plus"></i>
                </Button>
              ) : (
                <></>
              )}

              {new Check().permission(["102", "100"], "or") ? (
                <Button
                  size="sm"
                  variant="warning me-2"
                  type="button"
                  onClick={() => this.getRole(item.id)}
                  title="Chi tiết năm học"
                >
                  {new Check().permission(["102"]) ? (
                    <i className="fa-solid fa-pencil"></i>
                  ) : (
                    <i className="fa-solid fa-eye"></i>
                  )}
                </Button>
              ) : (
                <></>
              )}

              {new Check().permission(["103"]) ? (
                <Button
                  size="sm"
                  variant="danger"
                  type="button"
                  onClick={() => this.handleDelete(item)}
                  title="Xóa năm học"
                >
                  <i className="fas fa-trash"></i>
                </Button>
              ) : (
                <></>
              )}
            </>
          ),
        });

        return item;
      });
    }

    return (
      <DataTables
        data={data}
        columns={columns}
        selectedRows={selectedRows}
        expandableRows
        expandableRowsComponent={({ data }) => (
          <ExpandedComponent listday={data.listday} />
        )}
      />
    );
  }

  refreshRole = () => {
    this.setState({
      role: {
        id: "",
        role_name: "",
        is_rank: "",
        permission: [],
      },
    });
  };

  renderRoleList() {
    const modalClose = () => {
      this.setState({ modalRoleShow: false, showModalHoliday: false });
      this.refreshRole();
    };

    const modalSubmit = () => {
      this.setState({ modalRoleShow: false, showModalHoliday: false });
      this.refreshRole();
      this.getRoles();
    };

    return (
      <>
        <Card.Header>
          {new Check().permission(["101"]) ? (
            <Button
              type="button"
              variant="success"
              size="sm"
              style={{ float: "right" }}
              onClick={() => this.setState({ modalRoleShow: true })}
              title="Thêm năm học"
            >
              <i className="fa-solid fa-plus"></i> Thêm
            </Button>
          ) : (
            <></>
          )}
          <Card.Title>
            <i className="fas fa-list me-1"></i> Danh sách năm học
          </Card.Title>
        </Card.Header>

        <Card.Body>
          {new Check().permission(["100"]) ? this.dataTable() : <></>}
        </Card.Body>

        {this.state.modalRoleShow ? (
          <>
            <YearSchoolFormModal
              show={this.state.modalRoleShow}
              onHide={modalClose}
              modal={
                this.state.role.id === ""
                  ? {
                      title: "Thêm năm học",
                      button: (
                        <>
                          <i className="fa-solid fa-check" /> Lưu
                        </>
                      ),
                    }
                  : {
                      title: "Chi tiết năm học",
                      button: new Check().permission(["102"]) ? (
                        <>
                          <i className="fa-solid fa-check" /> Cập nhật
                        </>
                      ) : (
                        false
                      ),
                    }
              }
              datarole={this.state.role}
              submit={modalSubmit}
            />
          </>
        ) : null}

        {this.state.showModalHoliday ? (
          <>
            <HolidayModal
              show={this.state.showModalHoliday}
              onHide={modalClose}
              id_add={this.state.id_add}
              modal={
                this.state.role?.id === ""
                  ? {
                      title: "Thêm ngày nghỉ",
                      button: (
                        <>
                          <i className="fa-solid fa-check" /> Lưu
                        </>
                      ),
                      type: "add",
                    }
                  : {
                      title: "Chi tiết ngày nghỉ",
                      button: (
                        <>
                          <i className="fa-solid fa-check" /> Cập nhật
                        </>
                      ),
                      type: "edit",
                    }
              }
              datarole={this.state.role}
              submit={modalSubmit}
            />
          </>
        ) : null}
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Cài đặt</Breadcrumb.Item>
            <Breadcrumb.Item active>Năm học - Nghỉ lễ</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col md={12}>
              <Card className="mb-4">{this.renderRoleList()}</Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default YearSchool;
