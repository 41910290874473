import React, { Component } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { Button, Row, Col, Card, Container, Breadcrumb } from "react-bootstrap";
import DataTables from "../datatable/DataTables";
import RoleAddModal from "./RoleAddModal";
import Check from "../other/Check";
import api from "../../../helper/axiosInstance";
import moment from "moment";
import { getDate } from "../utils/helpers";
import RoleEditModal from "./RoleEditModal";

class RoleIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      showAddModal: false,
      showEditModal: false,
      roleId: "",
      checkload: true,
      permissions: [],
    };
  }

  componentDidMount() {
    document.title = "Chức vụ";
    this.getRoles();
    this.getPermissions();
  }

  getRoles = async () => {
    await api.get(`/role-shows`).then((res) => {
      this.setState({ checkload: false });
      this.setState({ roles: res.data.data });
    });
  };

  getPermissions = async () => {
    await api.get("/permission-shows").then((res) => {
      this.setState({ permissions: res.data.data });
    });
  };

  handleDelete = (item) => {
    swal({
      title: "Bạn muốn xóa chức vụ?",
      text: `"${item.role_name}"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    })
      .then((ok) => {
        if (ok) {
          api
            .delete(`/role-delete?id=${item.id}`)
            .then((res) => {
              toast(res.data.messages, { type: "success", autoClose: 1000 });
              this.getRoles();
            })
            .catch((error) => {});
        }
      })
      .catch((error) => {});
  };

  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  dataTable() {
    const columns = [
      {
        name: "Tên chức vụ",
        selector: (row) => row.role_name,
        sortable: true,
      },
      {
        name: "Cập nhật",
        selector: (row) => row.updated_at,
        sortable: true,
        right: true,
        cell: (row) => getDate(row.updated_at),
      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
      },
    ];

    const data = [];

    if (this.state.roles.length > 0) {
      this.state.roles.map((item, i) => {
        data.push({
          id: item.id,
          is_rank: item.is_rank,
          permission: item.permission,
          role_name: item.role_name,
          updated_at: item.updated_at,
          setting: (
            <>
              {new Check().permission(["62"]) ? (
                <Button
                  size="sm"
                  variant="warning me-2"
                  type="button"
                  onClick={() => {
                    this.setState({
                      showEditModal: true,
                      roleId: item.id,
                    });
                  }}
                  title="Chi tiết chức vụ"
                >
                  <i className="fa-solid fa-pencil"></i>
                </Button>
              ) : null}

              {new Check().permission(["65"]) && item.is_rank !== 1 ? (
                <Button
                  size="sm"
                  variant="danger"
                  type="button"
                  onClick={() => this.handleDelete(item)}
                  title="Xóa chức vụ"
                >
                  <i className="fas fa-trash"></i>
                </Button>
              ) : null}
            </>
          ),
        });

        return item;
      });
    }

    return <DataTables data={data} columns={columns} />;
  }

  renderRoleList() {
    const onSubmitAddModal = () => {
      this.setState({ showAddModal: false });
      this.getRoles();
    };

    return (
      <>
        <Card.Header>
          {new Check().permission(["63"]) ? (
            <Button
              type="button"
              variant="success"
              size="sm"
              style={{ float: "right" }}
              onClick={() => this.setState({ showAddModal: true })}
            >
              <i className="fa-solid fa-plus"></i> Thêm
            </Button>
          ) : null}
          <Card.Title>
            <i className="fas fa-list me-1"></i> Danh sách chức vụ
          </Card.Title>
        </Card.Header>

        <Card.Body>
          {this.state.checkload ? this.loading() : this.dataTable()}
        </Card.Body>

        {this.state.showAddModal ? (
          <>
            <RoleAddModal
              show={this.state.showAddModal}
              onHide={() => this.setState({ showAddModal: false })}
              permissions={this.state.permissions}
              onSubmit={onSubmitAddModal}
            />
          </>
        ) : null}

        {this.state.showEditModal ? (
          <>
            <RoleEditModal
              show={this.state.showEditModal}
              onHide={() => this.setState({ showEditModal: false })}
              roleId={this.state.roleId}
              permissions={this.state.permissions}
              onSubmit={() => {
                this.setState({ showEditModal: false });
                this.getRoles();
              }}
            />
          </>
        ) : null}
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Nhân viên</Breadcrumb.Item>
            <Breadcrumb.Item active>Chức vụ</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col md={6}>
              <Card className="mb-4">{this.renderRoleList()}</Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default RoleIndex;
