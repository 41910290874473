import React, { Component } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  Button,
  Row,
  Col,
  Card,
  Container,
  Breadcrumb,
  Alert,
  Tabs,
  Tab,
  Form,
} from "react-bootstrap";
import Check from "../other/Check";
import FormatData from "../FormatData";
import TuitionModal from "./TuitionModal";
import DataTables from "../datatable/DataTables";
import api from "../../../helper/axiosInstance";

class TuitionIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ingredents: [],
      ingredents_search: [],
      ingredentTextSearch: "",

      addModalShow: false,
      alertShow: false,
      ingredent: [],

      selectedRows: [],
      tabkey: "1",
      typeModal: "",
      tuition: [],
    };
  }

  componentDidMount() {
    document.title = "Khoản thu";
    this.getTuition(this.state.tabkey);
  }

  changeTab = (key) => {
    this.setState({ tabkey: key });

    this.getTuition(key);
    this.setState({ ingredentTextSearch: "" });
  };

  getTuition = (key) => {
    api.get("/tuition-shows").then((res) => {
      if (res.data.data?.status == true) {
        this.setState({
          tuition: res.data.data?.data?.filter((e) => e.type == key) || [],
        });
      }
    });
  };

  handleDelete = (event) => {
    let id_ingredent = event.currentTarget.dataset.ingredent_id;

    swal({
      title: "Bạn muốn xóa khoản thu?",
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    })
      .then((ok) => {
        if (ok) {
          api
            .delete(`/tuition-delete?id=${id_ingredent}`)
            .then((res) => {
              toast(res.data.messages, { type: "success" });
              this.getTuition(this.state.tabkey);
            })
            .catch((err) => {
              toast(err.response.data.messages || "Hệ thống xảy lỗi", {
                type: "error",
                autoClose: 1000,
              });
            });
        }
      })
      .catch((err) => {});
  };

  dataTable(key) {
    const columns = [
      {
        name: "Tên ",
        selector: (row) => row.name,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.name}
          </div>
        ),
      },
      {
        name: "Mô tả",
        selector: (row) => row.description,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.description}
          </div>
        ),
      },

      {
        name: "Giá (VND)",
        selector: (row) => parseFloat(row.amount),
        sortable: true,
        right: true,
        cell: (row) => new FormatData().currency(parseFloat(row.amount)),
      },

      {
        name: "",
        selector: (row) => row.setting,
        center: true,
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };

    if (this.state.tuition.length > 0) {
      this.state.tuition.map((item, i) => {
        data.push({
          ...item,
          setting: (
            <>
              {new Check().permission([134, 136], "or") ? (
                <Button
                  variant="warning me-2"
                  type="button"
                  size="sm"
                  onClick={() => this.addModalShow(item)}
                  title="Cập nhật khoản thu"
                >
                  <i className="fa-solid fa-pencil"></i>
                </Button>
              ) : null}

              {new Check().permission([137]) ? (
                <Button
                  variant="danger"
                  type="button"
                  size="sm"
                  onClick={this.handleDelete}
                  data-ingredent_id={item.id}
                  title="Xóa khoản thu"
                >
                  <i className="fas fa-trash"></i>
                </Button>
              ) : null}
            </>
          ),
        });

        return item;
      });
    }

    return (
      <DataTables data={data} columns={columns} selectedRows={selectedRows} />
    );
  }

  addModalShow = (item) => {
    this.setState({
      ingredent: item,
      addModalShow: true,
      typeModal: "CN",
    });
  };
  renderSelectedRows() {
    let num = this.state.selectedRows.length;
    if (num > 0 && this.state.alertShow === true) {
      return (
        <Alert
          variant="info mb-0 py-2"
          onClose={() => this.setState({ alertShow: false })}
          dismissible
        >
          {num} mục đã chọn.
        </Alert>
      );
    }
  }

  toSlug(str) {
    str = str.toLowerCase(); // Chuyển hết sang chữ thường
    // xóa dấu
    str = str
      .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
      .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
    str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
    str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
    return str;
  }

  handleIngredentSearch(root, text) {
    let root_ = root;
    this.setState({
      ingredentTextSearch: text,
    });
    if (text !== "") {
      root_ = this.state.ingredents.filter((e) =>
        this.toSlug(e.ingredent_name).includes(this.toSlug(text.trim()))
      );
    }
    this.setState({
      ingredents_search: root_,
    });
  }

  boxSearch() {
    return (
      <Col className="mb-2 " md={4}>
        <Form.Control
          style={{ height: 39 }}
          type="text"
          placeholder="Tìm nguyên liệu"
          value={this.state.ingredentTextSearch}
          onChange={(e) => {
            this.handleIngredentSearch(this.state.ingredents, e.target.value);
          }}
        />
      </Col>
    );
  }

  renderIngredentList() {
    const addModalClose = () => {
      this.setState({ addModalShow: false, ingredent: [] });
      this.getTuition(this.state.tabkey);
    };

    return (
      <>
        <Card.Header>
          {new Check().permission([135]) ? (
            <Button
              type="button"
              variant="success"
              size="sm"
              style={{ float: "right" }}
              onClick={() =>
                this.setState({ addModalShow: true, typeModal: "TM" })
              }
            >
              <i className="fa-solid fa-plus"></i> Thêm
            </Button>
          ) : null}
          <Card.Title>
            <i className="fas fa-list me-1"></i> Danh sách khoản thu
          </Card.Title>
        </Card.Header>

        <Card.Body>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey={this.state.tabkey}
                onSelect={(k) => {
                  this.changeTab(k);
                }}
                className="mb-3 "
              >
                <Tab key={1} title="Theo tháng" eventKey={1}>
                  {this.dataTable(1)}
                </Tab>
                <Tab key={0} title="Theo năm" eventKey={0}>
                  {this.dataTable(2)}
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Card.Body>

        {this.state.addModalShow ? (
          <TuitionModal
            show={this.state.addModalShow}
            onHide={addModalClose}
            keyTab={this.state.tabkey}
            typeModal={this.state.typeModal}
            obj={{ ingredent: this.state.ingredent }}
          />
        ) : (
          ""
        )}
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Cài đặt</Breadcrumb.Item>
            <Breadcrumb.Item active>Khoản thu</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col md={12}>
              <Card className="mb-4">{this.renderIngredentList()}</Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default TuitionIndex;
