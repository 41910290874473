import React, { Component } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Container,
  Tab,
  Table,
  Tabs,
  Row,
  Col,
  Modal,
  Form,
} from "react-bootstrap";
import * as XLSX from "xlsx-js-style";
import GroupCreate from "./GroupCreate";
import swal from "sweetalert";
import { toast } from "react-toastify";
import Check from "../other/Check";
import ClassCreate from "../class/ClassCreate";
import DataTables from "../datatable/DataTables";
import api from "../../../helper/axiosInstance";

class GroupIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listclass: [],
      tabkey: 0,
      listgroup: [],
      showcreate: false,
      groupname: "",
      idgroup: "",
      isMain: "",
      recommended_from: 0,
      recommended_to: 0,
      id_groupAge: 0,
      enegry_ratio: 0,
      alertShow: false,
      selectedRows: [],
      checkload: true,
      listclasssearch: [],
      showDetail: false,
      listclassID: [],
      listEthnic: [],
      _class: [],
      show: false,
      type: "",
      listuser: [],
    };
  }

  componentDidMount = () => {
    document.title = "Quản lý nhóm học sinh";
    this.getlistgroup();
    this.getEthnic();
    this.getlistuser();
  };

  getEthnic = () => {
    api
      .get("/viewEthnic")
      .then((res) => this.setState({ listEthnic: res.data.data }));
  };
  formclose = () => {
    this.setState({ show: false, err: {} });
    this.getlistclass(this.state.idgroup);
    this.getlistuser();
  };
  getlistuser = () => {
    api
      .get("/showAllUser")
      .then((res) => {
        if (res.data.status) {
          this.setState({
            listuser: res.data.data,
          });
        }
      })
      .catch((error) => {});
  };

  xport = () => {
    const table = document.getElementById("StudentTable");
    const wb = XLSX.utils.book_new();
    var ws = XLSX.utils.table_to_sheet(table, { raw: true });

    const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H"];
    var wscols = [
      { wch: 5 },
      { wch: 25 },
      { wch: 20 },
      { wch: 20 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 11 },
    ];
    ws["!cols"] = wscols;

    const range = XLSX.utils.decode_range(ws["!ref"]);
    const borderStyle = {
      font: {
        name: "Times New Roman",
        sz: 11,
      },
      alignment: {
        wrapText: true,
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
    };

    const startRow = 0;
    const endRow = range.e.r;

    for (let row = startRow; row <= endRow; row++) {
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
        const cell = ws[cellAddress];

        if (cell.s == undefined) cell.s = {};

        cell.s = { ...cell.s, ...borderStyle };
        if (cell.v === "empty") {
          cell.v = "";
        }
      }
    }

    colAlpha.map((alpha) => {
      ws[`${alpha}1`].s = {
        font: {
          name: "Times New Roman",
          sz: 11,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });

    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

    XLSX.writeFile(wb, "DSHS.xlsx");
  };

  changeEmpty = (element) => {
    return element == "" ||
      element == null ||
      element == "null" ||
      element == undefined ||
      element == "undefined"
      ? "empty"
      : element;
  };
  handleStudentSearch = (root_data, text) => {
    let root_ = root_data;
    if (text !== "") {
      root_ = this.state.listclassID.filter((e) =>
        this.toSlug(e.student_name).includes(this.toSlug(text))
      );
    }

    this.setState({ listclasssearch: root_ });
  };
  getDate = (data) => {
    const date = new Date(data * 1000);
    date.setHours(0, 0, 0, 0);
    return (
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear()
    );
    // return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
  };
  getClassbyId = (id) => {
    api.post(`/viewChilClassRoom?id_class=${id}`).then((res) => {
      this.setState({ listclassID: res.data.data });
      this.handleStudentSearch(res.data.data, "");
    });
  };
  toSlug = (str) => {
    str = str.toLowerCase(); // Chuyển hết sang chữ thường
    // xóa dấu
    str = str
      .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
      .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
    str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
    str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
    return str;
  };
  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };

  getlistgroup = () => {
    api
      .get("/group-show")
      .then((res) => {
        if (res.data.errCode == 0) {
          this.setState({ checkload: false });
          if (res.data.groups.length > 0) {
            var arr = res.data.groups;
            // .sort(function (a, b) {
            //   return b.is_main - a.is_main;
            // }
            // )
            arr.push({
              id: 0,
              group_name: "Chưa có nhóm",
              is_main: 1,
              is_adult: 0,
              notDel: 0,
              is_delete: 1,
            });
            this.setState({
              groupname: arr[this.state.tabkey]?.group_name,
              idgroup: arr[this.state.tabkey]?.id,
              isMain: arr[this.state.tabkey]?.is_main,
              recommended_from: arr[this.state.tabkey]?.recommended_from,
              recommended_to: arr[this.state.tabkey].recommended_to,
              enegry_ratio: arr[this.state.tabkey]?.enegry_ratio,
              id_groupAge: arr[this.state.tabkey]?.id_age_group,

              listgroup: arr,
            });
            this.getlistclass(arr[this.state.tabkey]?.id);
          } else {
            this.setState({ listgroup: [] });
          }
        }
      })
      .catch((error) => {});
  };

  getlistclass = (id) => {
    api
      .post(`/viewChilClassGroup?id_group=${id}`)
      .then((res) => {
        if (res.data.status) {
          this.setState({
            listclass: res.data.data,
          });
        }
      })
      .catch((error) => {});
  };

  deletegroup = (event) => {
    let formData = new FormData();
    formData.append("id", this.state.idgroup);

    swal({
      title: "Bạn muốn xóa nhóm học sinh?",
      text: "Lưu ý: Tất cả định lượng Món ăn liên quan đến Nhóm sẽ bị xóa!",
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        api({
          method: "delete",
          url: "group-destroy",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((res) => {
            this.getlistgroup();

            toast(`${res.data.message}`, {
              type: res.data.errCode == 0 ? "success" : "error",
              autoClose: 1000,
            });
          })
          .catch((error) => {});
      }
      this.getlistgroup();
    });
  };
  deleteclass = (id, event) => {
    swal({
      title: "Xác nhận xóa lớp học",
      icon: "error",
      buttons: true,
      successMode: true,
    }).then((ok) => {
      if (ok) {
        api
          .post(`/deleteClassRoom?id_class=${id}`)
          .then((res) => {
            if (res.data.status) {
              this.getlistclass(this.state.idgroup);

              // this.getlistclass();
              toast(`${res.data.data.mess}`, {
                type: "success",
                autoClose: 1000,
              });
            }
          })
          .catch((error) => {});
      }
    });
  };
  creategroupClose = () => {
    this.setState({ showcreate: false, err: {} });
    this.setState({
      // recommended_from:0,
      // recommended_to:0
    });
    this.getlistgroup();
  };

  renderAddGroupModal = () => {
    return (
      <GroupCreate
        groupname={this.state.groupname}
        idgroup={this.state.idgroup}
        PT={this.state.type}
        show={this.state.showcreate}
        close={this.creategroupClose}
        recommended_from={this.state.recommended_from}
        recommended_to={this.state.recommended_to}
        enegry_ratio={this.state.enegry_ratio}
        id_groupAge={this.state.id_groupAge}
      />
    );
  };
  dataTable() {
    const columns = [
      {
        name: "Tên lớp",
        selector: (row) => row.class_name,
        sortable: true,
      },
      {
        name: "Giáo viên",
        selector: (row) => row.user_name,
        sortable: true,
        style: {
          div: {
            width: "250px",
          },
        },
      },

      {
        name: "Số lượng HS",
        selector: (row) => row.count_student,
        sortable: true,
        center: true,
      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        // sortable: true,
        center: true,
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };

    if (this.state.listclass.length > 0) {
      this.state.listclass.map((item, i) => {
        data.push({
          // id: item.id,
          // <td>{item.class_name}</td>
          //                                         <td>{item.user_name}</td>
          //                                         <td className="text-center">{item.count_student}</td>
          class_name: item.class_name,
          user_name: item.user_name,
          count_student: item.count_student,
          setting: (
            <>
              {new Check().permission(["12"]) ? (
                <>
                  <Button
                    variant="primary"
                    title="Xem chi tiết"
                    size="sm"
                    disabled={item.count_student < 1}
                    onClick={() => {
                      this.setState({ showDetail: true });
                      this.getClassbyId(item.id);
                    }}
                  >
                    <i className="fa-solid fa-circle-info" />
                  </Button>{" "}
                </>
              ) : null}
              <Button
                variant="warning"
                title="Cập nhật"
                size="sm"
                // style={{color:'white'}}
                onClick={() => {
                  this.setState({
                    type: "CN",
                    show: true,
                  });
                  this.setState({
                    _class: {
                      id: item.id,
                      id_group: item.id_group,
                      id_user: item.id_user,
                      class_name: item.class_name,
                    },
                  });
                }}
              >
                <i className="fa-solid fa-pencil"></i>
              </Button>
              &nbsp;
              <Button
                variant="danger"
                title="Xóa"
                size="sm"
                onClick={() => {
                  this.deleteclass(item.id);
                }}
              >
                <i className="fas fa-trash"></i>
              </Button>
            </>
          ),
        });

        return item;
      });
    }

    const totalStudent = data.reduce(
      (total, record) => total + record.count_student,
      0
    );
    const totalRow = {
      id: "total-student",
      name: "Tổng",
      class_name: "Tổng",
      count_student: totalStudent,
    };

    const temporaryData = [...data, totalRow];
    return (
      <>
        <DataTables
          data={temporaryData}
          columns={columns}
          selectedRows={selectedRows}
        />
      </>
    );
  }
  render() {
    const data = (
      <>
        <Container fluid>
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Học sinh</Breadcrumb.Item>
            <Breadcrumb.Item active>Nhóm học sinh</Breadcrumb.Item>
          </Breadcrumb>

          <Card>
            <Card.Header>
              {new Check().permission(["8"]) ? (
                <Button
                  type="button"
                  variant="success"
                  style={{ marginLeft: "5px", float: "right" }}
                  size="sm"
                  onClick={() => {
                    this.setState({
                      showcreate: true,
                      type: "TM",
                      groupname: "",
                      recommended_from: 0,
                      recommended_to: 0,
                      id_groupAge: 0,
                      enegry_ratio: 0,
                    });
                  }}
                >
                  <i className="fa-solid fa-plus"></i> Thêm nhóm
                </Button>
              ) : null}
              {new Check().permission(["7"]) ? (
                <Button
                  hidden={
                    this.state.listgroup.length < 1 || this.state.idgroup == 0
                      ? true
                      : false
                  }
                  type="button"
                  variant="warning"
                  size="sm"
                  style={{ marginLeft: "5px", float: "right" }}
                  onClick={() => {
                    this.setState({
                      type: "CN",
                      recommended_from: this.state.recommended_from,
                      recommended_to: this.state.recommended_to,
                      enegry_ratio: this.state.enegry_ratio,
                      id_groupAge: this.state.id_groupAge,
                      showcreate: true,
                    });
                  }}
                >
                  <i className="fa-solid fa-edit" /> Sửa nhóm
                </Button>
              ) : null}
              {new Check().permission(["10"]) ? (
                <>
                  <Button
                    hidden={
                      this.state.listgroup.length < 1 ||
                      Number(this.state.isMain == 0 || this.state.idgroup == 0)
                        ? true
                        : false
                    }
                    type="button"
                    variant="danger"
                    size="sm"
                    style={{ marginLeft: "5px", float: "right" }}
                    onClick={() => {
                      this.deletegroup();
                    }}
                    // disabled={ this.state.idgroup == 0 }
                  >
                    <i className="fa-solid fa-trash" /> Xóa nhóm
                  </Button>{" "}
                </>
              ) : null}
              <Card.Title>
                <i className="fas fa-list me-1"></i> Danh sách nhóm học sinh
              </Card.Title>
            </Card.Header>
            <Card.Body>
              {this.state.checkload ? (
                this.loading()
              ) : this.state.listgroup.length > 1 ? (
                <>
                  <Button
                    style={{ float: "right", marginTop: "10px" }}
                    variant="success"
                    size="sm"
                    hidden={
                      this.state.listgroup.length < 1 ||
                      Number(this.state.isMain) === 0
                        ? true
                        : false
                    }
                    onClick={() => {
                      this.setState({
                        _class: [],
                        show: true,
                        type: "TM",
                      });
                    }}
                  >
                    {" "}
                    <i className="fa-solid fa-plus"></i> Thêm lớp
                  </Button>
                  <Tabs
                    activeKey={this.state.tabkey}
                    defaultActiveKey={0}
                    onSelect={(k) => {
                      let group = this.state.listgroup[k];
                      this.setState({
                        tabkey: k,
                        groupname: group.group_name,
                        idgroup: group.id,
                        isMain: group.is_main,
                        recommended_from: group.recommended_from,
                        id_groupAge: group.id_age_group,
                        recommended_to: group.recommended_to,
                        enegry_ratio: group.enegry_ratio,
                      });
                      this.getlistclass(group.id);
                    }}
                    className="mb-3"
                  >
                    {this.state.listgroup.map((group, index) => {
                      if (group.is_adult == 0) {
                        return (
                          <Tab
                            key={index}
                            title={group.group_name}
                            eventKey={index}
                          >
                            {Number(this.state.isMain) === 0 ? (
                              ""
                            ) : this.state.listclass.length > 0 ? (
                              this.dataTable()
                            ) : (
                              <p className="text-center">Không có dữ liệu...</p>
                            )}
                          </Tab>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Tabs>
                </>
              ) : (
                <p className="text-center">Không có dữ liệu ...</p>
              )}
            </Card.Body>
          </Card>
        </Container>

        <ClassCreate
          _class={this.state._class}
          listuser={this.state.listuser}
          PT={this.state.type}
          show={this.state.show}
          close={this.formclose}
          listclass={this.state.listclass}
          user_now={this.state._class.id_user}
          id_group={this.state.idgroup}
        />

        {this.state.showcreate ? this.renderAddGroupModal() : ""}
        <Modal
          size="lg"
          show={this.state.showDetail}
          onHide={() => this.setState({ showDetail: false })}
          keyboard={false}
        >
          <Modal.Header closeButton>
            <th> Chi tiết lớp {this.state.listclasssearch[0]?.class_name}</th>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col style={{ marginBottom: "15px" }} md={6}>
                <Form.Control
                  style={{ height: 39 }}
                  type="text"
                  placeholder="Nhập tên học sinh"
                  onChange={(e) => {
                    this.handleStudentSearch(
                      this.state.listclassID,
                      e.target.value.trim()
                    );
                  }}
                />
              </Col>
              <Col md={6}>
                <Button
                  variant="success"
                  className="text-end"
                  size="sm"
                  style={{ marginLeft: "5px", float: "right" }}
                  onClick={() => this.xport()}
                >
                  Xuất DSHS lớp {this.state.listclasssearch[0]?.class_name} {}
                  <i className="fa-solid fa-file-export"></i>
                </Button>
              </Col>
            </Row>
            {this.state.checkload === true ? (
              this.loading()
            ) : this.state.listclasssearch.length > 0 ? (
              <Table
                bordered
                striped
                //  id="StudentTable"
              >
                <thead>
                  <tr>
                    <th className="align-middle text-center">STT</th>
                    <th className="align-middle text-center">
                      {" "}
                      Mã định danh Bộ GD&ĐT
                    </th>
                    <th className="align-middle text-center">Họ và tên</th>
                    <th className="align-middle text-center">
                      {" "}
                      Ngày tháng năm sinh
                    </th>
                    <th className="align-middle text-center">Giới tính</th>
                    <th className="align-middle text-center">Dân tộc</th>
                    <th className="align-middle text-center">Trạng thái</th>
                    <th className="align-middle text-center">SĐT liên hệ</th>
                  </tr>

                  {this.state.listclasssearch.map((item, index) => {
                    return (
                      <tr key={item.id}>
                        <td className="text-center">{index + 1}</td>
                        <td>
                          {item.student_identifier == "null"
                            ? ""
                            : item.student_identifier}
                        </td>
                        <td>{item.student_name}</td>
                        {/* <td>{this.getDate(item.student_birthday)}</td> */}
                        <td>{this.getDate(item.student_birthday)}</td>
                        {/* <td>{this.getMonth_(item.student_birthday)}</td>
                              <td>{this.getYear_(item.student_birthday)}</td> */}
                        <td>{item.gender_name}</td>
                        <td>
                          {
                            this.state.listEthnic.filter(
                              (e) => e.id == item.student_ethnic
                            )[0]?.ethnic_name
                          }
                        </td>
                        <td>
                          {item.student_status == 1 ? "Đang học" : "Tạm nghỉ"}
                        </td>
                        <td>
                          {item.student_contact == "null"
                            ? ""
                            : item.student_contact}
                        </td>
                      </tr>
                    );
                  })}

                  <tr>
                    <th colSpan={4} className="text-center">
                      Số lượng
                    </th>
                    <th colSpan={4}> {this.state.listclasssearch.length}</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </Table>
            ) : (
              <p className="text-center"> Không có dữ liệu</p>
            )}
            {this.state.listclasssearch.length > 0 ? (
              <Table bordered id="StudentTable" className="d-none">
                <thead>
                  <tr>
                    <th className="align-middle text-center">STT</th>
                    <th className="align-middle text-center">
                      {" "}
                      Mã định danh Bộ GD&ĐT
                    </th>
                    <th className="align-middle text-center">Họ và tên</th>
                    <th className="align-middle text-center">
                      {" "}
                      Ngày tháng năm sinh
                    </th>
                    <th className="align-middle text-center">Giới tính</th>
                    <th className="align-middle text-center">Dân tộc</th>
                    <th className="align-middle text-center">Trạng thái</th>
                    <th className="align-middle text-center">SĐT liên hệ</th>
                  </tr>

                  {this.state.listclasssearch.map((item, index) => {
                    return (
                      <tr key={item.id}>
                        <td className="text-center">{index + 1}</td>
                        <td>{this.changeEmpty(item.student_identifier)}</td>
                        <td>{this.changeEmpty(item.student_name)}</td>
                        {/* <td>{this.getDate(item.student_birthday)}</td> */}
                        <td>
                          {this.changeEmpty(
                            this.getDate(item.student_birthday)
                          )}
                        </td>
                        {/* <td>{this.getMonth_(item.student_birthday)}</td>
                              <td>{this.getYear_(item.student_birthday)}</td> */}
                        <td>{this.changeEmpty(item.gender_name)}</td>
                        <td>
                          {this.changeEmpty(
                            this.state.listEthnic.filter(
                              (e) => e.id == item.student_ethnic
                            )[0]?.ethnic_name
                          )}
                        </td>
                        <td>
                          {this.changeEmpty(
                            item.student_status == 1 ? "Đang học" : "Tạm nghỉ"
                          )}
                        </td>
                        <td>
                          {this.changeEmpty(
                            item.student_contact == "null"
                              ? ""
                              : item.student_contact
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </thead>
                <tbody></tbody>
              </Table>
            ) : (
              <p className="text-center"> Không có dữ liệu</p>
            )}
          </Modal.Body>
        </Modal>
      </>
    );

    return data;
  }
}

export default GroupIndex;
