import { toast } from "react-toastify";
import { message } from "../../../constants/message";
import { QUANTITIATIVE_ZONE } from "../../../constants";

const groupArrayByField = (arr, key) => {
  return [
    ...arr
      .reduce(
        (acc, o) => acc.set(o[key], (acc.get(o[key]) || []).concat(o)),
        new Map()
      )
      .values(),
  ];
};

const priceFormatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
const priceFormatterNoDecimal = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const dateFormatter = (date) => {
  return (
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    date.getFullYear()
  );
};

const takeDecimalNumber = (num, n) => {
  let base = 10 ** n;
  let result = Math.round(num * base) / base;
  return result;
};

const getDate = (data) => {
  const date = new Date(data * 1000);
  return (
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    date.getFullYear() +
    " " +
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2) +
    ":" +
    ("0" + date.getSeconds()).slice(-2)
  );
};

const getTime = (data) => {
  const date = new Date(data * 1000);
  return (
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2)
    //  +
    // ":" +
    // ("0" + date.getSeconds()).slice(-2)
  );
};

const toastErrorMessage = (error) => {
  let mess = "";

  switch (error.response.status) {
    case 400:
      mess = message.errorBadRequest;
      break;
    case 500:
      mess = error.response.data.messages || message.errorInternalServer;
      break;
    default:
      mess = message.errorUnknown;
  }

  return toast(mess, { type: "error", autoClose: 1000 });
};

const toSlug = (str) => {
  str = str.toLowerCase(); // Chuyển hết sang chữ thường
  // xóa dấu
  str = str
    .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
    .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
  str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
  str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
  return str;
};

const findeQuantitativeZone = (id_age) => {
  if (id_age >= 1 && id_age <= 3) {
    return QUANTITIATIVE_ZONE[0];
  } else if (id_age === 4) {
    return QUANTITIATIVE_ZONE[1];
  } else if (id_age >= 5) {
    return QUANTITIATIVE_ZONE[2];
  }
  return null;
};

const isInRange = (number, attribute, data) => {
  if (data?.hasOwnProperty(attribute)) {
    const min = data[attribute].MIN;
    const max = data[attribute].MAX;
    return number < min || number > max;
  }
  return false;
};

const isDecimal = (value) => {
  return value % 1 !== 0;
};

const getlastDayOfMonth = (year, month) => {
  var nextMonth = new Date(year, month + 1, 1);

  var lastDay = new Date(nextMonth.getTime() - 1);

  return new Date(lastDay.setHours(23, 59, 59));
};

const getNutritionalColorByRange = (number, attribute, data) => {
  if (data?.hasOwnProperty(attribute)) {
    const min = data[attribute].MIN;
    const max = data[attribute].MAX;

    if (number < min) {
      return "text-info";
    } else if (number > max) {
      return "text-danger";
    } else {
      return "text-success";
    }
  }
  return "";
};
const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const soThanhChu = (so) => {
  const donVi = [
    "",
    "một",
    "hai",
    "ba",
    "bốn",
    "năm",
    "sáu",
    "bảy",
    "tám",
    "chín",
  ];
  const chuc = [
    "",
    "mười",
    "hai mươi",
    "ba mươi",
    "bốn mươi",
    "năm mươi",
    "sáu mươi",
    "bảy mươi",
    "tám mươi",
    "chín mươi",
  ];
  const hang = ["", "nghìn", "triệu", "tỷ", "nghìn tỷ", "triệu tỷ", "tỷ tỷ"];

  if (so === 0) return "Không";

  let chuoi = "";
  let i = 0;

  while (so > 0) {
    let donViHangNghin = so % 1000;
    if (donViHangNghin !== 0) {
      chuoi = soThanhChuHangNghin(donViHangNghin) + " " + hang[i] + " " + chuoi;
    }
    so = Math.floor(so / 1000);
    i++;
  }

  return chuoi.trim() + " đồng";
};

const soThanhChuHangNghin = (so) => {
  const donVi = [
    "",
    "một",
    "hai",
    "ba",
    "bốn",
    "năm",
    "sáu",
    "bảy",
    "tám",
    "chín",
  ];
  const chuc = [
    "",
    "mười",
    "hai mươi",
    "ba mươi",
    "bốn mươi",
    "năm mươi",
    "sáu mươi",
    "bảy mươi",
    "tám mươi",
    "chín mươi",
  ];

  let chuoi = "";

  if (so < 10) {
    chuoi = donVi[so];
  } else if (so < 100) {
    chuoi =
      chuc[Math.floor(so / 10)] + (so % 10 !== 0 ? " " + donVi[so % 10] : "");
  } else if (so < 1000) {
    chuoi =
      donVi[Math.floor(so / 100)] +
      " trăm " +
      chuc[Math.floor((so % 100) / 10)] +
      (so % 10 !== 0 ? " " + donVi[so % 10] : "");
  }

  return chuoi.trim();
};

const arraysAreEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = [...arr1].sort((a, b) => a - b);
  const sortedArr2 = [...arr2].sort((a, b) => a - b);

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
};

export {
  groupArrayByField,
  priceFormatter,
  dateFormatter,
  takeDecimalNumber,
  getDate,
  toastErrorMessage,
  toSlug,
  priceFormatterNoDecimal,
  getTime,
  findeQuantitativeZone,
  isInRange,
  isDecimal,
  getNutritionalColorByRange,
  getlastDayOfMonth,
  soThanhChu,
  capitalizeFirstLetter,
  arraysAreEqual,
};
