import React, { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import Select from "react-select";
import FormSettingDatePicker from "./FormSettingDatePicker";
import api from "../../../../helper/axiosInstance";

const Book2 = ({ formSetting, setFormSetting, times }) => {
  const [userOption, setUserOption] = useState([]);

  useEffect(() => {
    document.title = "Cài đặt biểu mẫu";
    getUserData();
  }, []);

  const getUserData = async () => {
    await api
      .get("/showAllUser")
      .then((res) => {
        if (res.data.status) {
          setUserOption(
            res.data.data.map((user) => {
              return {
                value: user.id,
                label: user.user_name,
              };
            })
          );
        }
      })
      .catch((error) => {});
  };

  const qty_merge =
    times.filter((itemFilter) => itemFilter.time_name_now !== null)?.length ||
    1;
  let show_item_single = "";

  return (
    <div style={{ overflowX: "scroll" }}>
      <Table bordered style={{ minWidth: 999 }}>
        <thead>
          <tr>
            <td colSpan={2} className="align-middle">
              Người kiểm tra:
            </td>
            <td>
              <Form.Control
                type="text"
                value={formSetting.step2_checker}
                onChange={(e) =>
                  setFormSetting({
                    ...formSetting,
                    step2_checker: e.target.value,
                  })
                }
              />
            </td>
            <td className="align-middle">
              <Form.Check
                type="switch"
                checked={formSetting.step2_show_inventory === 1 ? true : false}
                onChange={(e) =>
                  setFormSetting({
                    ...formSetting,
                    step2_show_inventory: e.target.checked ? 1 : 0,
                  })
                }
                label="Hiển thị hàng kho"
              />
            </td>
          </tr>

          <tr>
            <td colSpan={2} className="align-middle">
              Địa điểm kiểm tra:
            </td>
            <td colSpan={2}>
              <Form.Control
                type="text"
                value={formSetting.step2_check_address || ""}
                onChange={(e) =>
                  setFormSetting({
                    ...formSetting,
                    step2_check_address: e.target.value,
                  })
                }
              />
            </td>
          </tr>
        </thead>

        <thead>
          <tr className="text-center">
            <td
              className="align-middle"
              rowSpan={2}
              style={{ minWidth: "8rem" }}
            >
              Buổi
            </td>
            <td className="align-middle" rowSpan={2}>
              Thời gian sơ chế xong <br />
              (ngày, giờ)
            </td>
            <td className="align-middle" rowSpan={2}>
              Thời gian chế biến xong <br />
              (ngày, giờ)
            </td>
            <td className="align-middle" colSpan={3}>
              Kiểm tra điều kiện vệ sinh <br />
              (từ thời điểm bắt đầu sơ chế, chế biến cho đến khi thức ăn được
              chế biến xong)
            </td>
          </tr>
          <tr className="text-center">
            <td className="align-middle">Người tham gia chế biến</td>
            <td className="align-middle">Trang thiết bị dụng cụ</td>
            <td className="align-middle">Khu vực chế biến và phụ trợ</td>
          </tr>
          <tr className="text-center">
            <td className="align-middle"></td>
            <td className="align-middle">(6)</td>
            <td className="align-middle">(7)</td>
            <td className="align-middle">(8)</td>
            <td className="align-middle">(9)</td>
            <td className="align-middle">(10)</td>
          </tr>
        </thead>
        <tbody>
          {times.map((timeItem, timeIndex) => {
            if (show_item_single === "" && timeItem.time_name_now !== null)
              show_item_single = timeIndex;

            return (
              <tr
                key={`time_${timeIndex}`}
                className={timeItem.time_name_now === null ? "d-none" : ""}
              >
                <td>{timeItem.time_name_now}</td>
                <td>
                  <FormSettingDatePicker
                    selected={
                      Number(
                        formSetting.step2_6?.filter(
                          (e) => e.time == timeItem.id
                        )[0]?.val
                      ) || new Date()
                    }
                    onChange={(date) =>
                      setFormSetting({
                        ...formSetting,
                        step2_6: formSetting.step2_6?.map(
                          (timeObj, timeObjIndex) => {
                            if (timeItem.id == timeObj.time) {
                              return {
                                ...timeObj,
                                time: timeItem.id,
                                val: date.getTime(),
                              };
                            }
                            return timeObj;
                          }
                        ),
                      })
                    }
                  />
                </td>
                <td>
                  <FormSettingDatePicker
                    selected={
                      Number(
                        formSetting.step2_7?.filter(
                          (e) => e.time == timeItem.id
                        )[0]?.val
                      ) || new Date()
                    }
                    onChange={(date) =>
                      setFormSetting({
                        ...formSetting,
                        step2_7: formSetting.step2_7?.map(
                          (timeObj, timeObjIndex) => {
                            if (timeItem.id == timeObj.time) {
                              return {
                                ...timeObj,
                                time: timeItem.id,
                                val: date.getTime(),
                              };
                            }

                            return timeObj;
                          }
                        ),
                      })
                    }
                  />
                </td>
                {timeIndex === show_item_single ? (
                  <React.Fragment>
                    <td
                      rowSpan={qty_merge}
                      className="text-center align-middle"
                    >
                      <Select
                        options={userOption}
                        placeholder={<div>Chọn người tham gia</div>}
                        value={formSetting.step2_8}
                        onChange={(choice) =>
                          setFormSetting({
                            ...formSetting,
                            time: timeItem.id,
                            step2_8: choice,
                          })
                        }
                        isMulti
                        menuPortalTarget={document.querySelector("body")}
                      />
                    </td>
                    <td
                      rowSpan={qty_merge}
                      className="text-center align-middle"
                    >
                      <Form.Control
                        type="text"
                        value={formSetting.step2_9}
                        onChange={(e) =>
                          setFormSetting({
                            ...formSetting,
                            time: timeItem.id,
                            step2_9: e.target.value,
                          })
                        }
                      />
                    </td>
                    <td
                      rowSpan={qty_merge}
                      className="text-center align-middle"
                    >
                      <Form.Control
                        type="text"
                        value={formSetting.step2_10}
                        onChange={(e) =>
                          setFormSetting({
                            ...formSetting,
                            time: timeItem.id,
                            step2_10: e.target.value,
                          })
                        }
                      />
                    </td>
                  </React.Fragment>
                ) : (
                  <></>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default Book2;
